import React from 'react';
import { Line } from 'react-chartjs-2';
import { formatDateRange } from '../../../../core/auxilary/formatting';
import { config } from '../../../../services/config';
import { IMineceptDashboardOverallEventsOverTime } from '../../../../store/reducers/mining/IMiningReducerState';
import { reverse } from '../../utils';
import { useSelector } from 'react-redux';
import { getSites } from '../../../../store/selectors/mining/mining.selectors';

interface IProps {
	graph: IMineceptDashboardOverallEventsOverTime;
	sitesIdToLabelMap: { [key: string]: string };
	period: number;
}

const Chart = ({ graph, sitesIdToLabelMap, period }: IProps) => {
	const sites = useSelector(getSites);
	const siteIds: string[] = (sites || []).map(x => x.id);

	const getSiteColor = (site: string) => {
		const index = Math.max(0, siteIds.indexOf(site));
		const fixedIndex = index % config.general.dashboard.siteColors.length;
		return config.general.dashboard.siteColors[fixedIndex];
	};

	return (
		<Line
			options={{
				plugins: {
					title: {
						display: true,
						text: 'Safety events per site over time',
					},
					datalabels: {
						display:
							config.general.dashboard.showDataLabels
								.overallEventsOverTime,
						color: 'black',
						labels: {
							title: {
								font: {
									weight: 'bold',
								},
							},
						},
					},
				},
				maintainAspectRatio: false,
				scales: {
					y: { beginAtZero: true },
				},
			}}
			data={{
				labels: reverse(graph.buckets).map(range =>
					formatDateRange(range, period === 1)
				),
				datasets: Object.entries(graph.data)
					.sort((a, b) => a[0].localeCompare(b[0]))
					.map(([site, dataset], index) => ({
						label: sitesIdToLabelMap[site],
						data: reverse(dataset),
						backgroundColor: getSiteColor(site),
						tension: 0.2,
						borderColor: getSiteColor(site),
						borderWidth: 1,
					})),
			}}
		/>
	);
};

export default Chart;
