import { Fill, Stroke, Style } from 'ol/style';

const colorMap = [
	'#514EE4',
	'#6B68EC',
	'#7185EE',
	'#85A7FE',
	'#7AB9F6',
	'#53C8EB',
	'#56CFE1',
	'#64DFDF',
	'#72EFDD',
	'#80FFDB',
	'#BCFFEC',
	'#E0FFF4',
	'#FFFFFF',
];

const styleFunction = (feature) => {
	const height = Math.max(Math.min(feature.values_.height, 2), 0);
	let colorStr = 'rgba(148,23,29,0.5)';
	let strokeWidth = 2;
	let lineDash =[1,1];
	if (feature.values_.entity === 'heightLine') {
		colorStr = colorMap[Math.round(height * 4)];
	} else if (feature.values_.entity === 'polyLine') {
		colorStr = '#F9E40F';
		strokeWidth = 3;
		lineDash= [8,8];
	}
	return new Style({
		stroke: new Stroke({
			color: colorStr,
			width: strokeWidth,
			lineDash,
		}),
		fill: new Fill({
			color: colorStr,
		}),
	});
};

export default styleFunction;
