import React, {useContext, useEffect, useRef, useState} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import useStyles from './videoPlayer.css';
import CloseIcon from '@material-ui/icons/Close';
import FullScreen from '@material-ui/icons/Fullscreen';
import FullScreenExit from '@material-ui/icons/FullscreenExit';
import {setEventPlayed} from '../../store/actions/mining.actions';
import {getPlayedEvent} from '../../store/selectors/mining/mining.selectors';
import {config} from '../../services/config';
import {showMiniMap} from "../../services/obstacles";
import {
    createPlayableTimeline,
    getSectorsByTime,
    getObstacleByTime,
    getSectors,
    ITimelinePlaybackSectors,
    ITimelinePlaybackObstacle
} from '../../services/video';
import videoContext from '../../store/context/videoContext';
import {IObstacleMiniMap} from '../mining-truck/miniMap/MinMap';
import { IMineceptVideoEvent, ITimelineObstacle } from '../../store/reducers/mining/IMiningReducerState';

interface IVideoSize {
    width: number;
    height: number;
}

const miniMapSize = parseInt(process.env.REACT_APP_MINI_MAP_SIZE as string);

function VideoPlayer() {
    const {isMinimized, isAutoPlay, isLoop, isShowControls} = config.general.video;
    const timeDeltaForWheelUpdate = config.general.video.timeDeltaForWheelUpdate;
    const [isClosed, setIsClosed] = useState(true);
    const [isFullScreen, setIsFullScreen] = useState(!isMinimized);
    const [videoSize, setVideoSize] = useState<IVideoSize | undefined>(undefined);
    const dispatch = useDispatch();
    const playedEvent: IMineceptVideoEvent = useSelector(getPlayedEvent);
    const classes = useStyles({videoSize, miniMapSize, isFullScreen});
    const videoRef = useRef<HTMLVideoElement | null>(null);
    const sectorsRef = useRef<ITimelinePlaybackSectors[]>([]);
    const obstaclesRef = useRef<ITimelinePlaybackObstacle[]>([]);
    const videoCtx = useContext(videoContext);
    const [wheelSectors, setWheelSectors] = useState<Array<number>>(getSectors());
    const [wheelObstacle, setWheelObstacle] = useState<Array<IObstacleMiniMap> | undefined>(undefined);
    const lastProcessVideoTime = useRef<number>(0);
    useEffect(() => {
        const fullscreenHandler = () => {
            if (!document.fullscreenElement && isFullScreen) {
                document.exitFullscreen().catch(() => {});
                setIsFullScreen(false);
            }
        };
        document.addEventListener('fullscreenchange', fullscreenHandler);
        return () => document.removeEventListener('fullscreenchange', fullscreenHandler)
    })

    useEffect(() => {
        if (!playedEvent) {
            setIsClosed(true);
            return;
        }
        setIsClosed(false);
        if (videoRef.current) {
            videoRef.current.load();
        }
        // Restore "normal" view
        if (isFullScreen) {
            toggleFullScreen();
        }
        const sectorsAndObstacles = playedEvent && createPlayableTimeline(playedEvent);
        sectorsRef.current = sectorsAndObstacles.sectors;
        obstaclesRef.current = sectorsAndObstacles.obstacles;
        setWheelSectors(getSectors());
        setWheelObstacle(undefined);
    }, [playedEvent]);

    const currentTimeChangeHandler = (value): void => {
        videoCtx.currentTimeChange(value);
    };
    const lastPlayedEventTimeChangeHandler = (value): void => {
        videoCtx.lastPlayedEventTimeChange(value);
    };
    const isPlayedChangeHandler = (value): void => {
        videoCtx.isPlayedChange(value);
    };
    const clearContextHandler = (): void => {
        videoCtx.clearContext();
    };
    const isObstacleEqual = (prevObstacle: Array<IObstacleMiniMap> | undefined, 
        currObstacle: ITimelineObstacle | undefined): Boolean => {
        if (prevObstacle && currObstacle) {
            return (prevObstacle[0].severity  === currObstacle.severity) &&
                (prevObstacle[0].obstacleType === currObstacle.Type); 
        }
        return !prevObstacle && !currObstacle;
    };

    const timelineUpdate = (event) => {
        event.preventDefault();
        const currentTime = Math.floor(videoRef.current && videoRef.current.currentTime * 1000 || 0);
        const isEnoughTimePassed = (currentTime - lastProcessVideoTime.current) >= timeDeltaForWheelUpdate;
        const isGoingBack = currentTime < lastProcessVideoTime.current;
        if (isEnoughTimePassed || isGoingBack) {
            currentTimeChangeHandler(currentTime);
            const updatedSectors = getSectorsByTime(currentTime, sectorsRef.current);
            if (updatedSectors !== undefined) {
                setWheelSectors(updatedSectors);
            }
            const obstacleData = getObstacleByTime(currentTime, obstaclesRef.current);
            const obstacle = obstacleData && obstacleData.value;
            if (!obstacleData && (currentTime < videoCtx.currentTime)) {
                lastPlayedEventTimeChangeHandler(currentTime);
                isPlayedChangeHandler(true);
                setWheelObstacle(undefined);
                currentTimeChangeHandler(currentTime);
            } else if (obstacleData && !isObstacleEqual(wheelObstacle, obstacle)) {
                const currentObstacle = obstacle ? [{
                    severity: obstacle.severity,
                    obstacleType: obstacle.Type,
                    obstacleDescription: ''
                }] : undefined;
                lastPlayedEventTimeChangeHandler(obstacleData.timestamp);
                setWheelObstacle(currentObstacle);
                if (videoRef.current && videoRef.current.paused) {
                    isPlayedChangeHandler(false);
                }
            }
            lastProcessVideoTime.current = currentTime;
        }
    };

    const pause = (event) => {
        event.preventDefault();
        isPlayedChangeHandler(false);
    };

    const play = (event) => {
        event.preventDefault();
        isPlayedChangeHandler(true);
    };

    const reset = (event) => {
        event.preventDefault();
        clearContextHandler();
        setWheelSectors(getSectors());
        setWheelObstacle(undefined);
    };

    const closeVideo = () => {
        setVideoSize(undefined);
        if (isFullScreen) {
            document.exitFullscreen().catch(() => {
            });
        }
        clearContextHandler();
        isPlayedChangeHandler(false);
        dispatch(setEventPlayed(undefined));
    };
    const toggleFullScreen = () => {
        if (isFullScreen) {
            document.exitFullscreen().catch(() => {
            });
        } else {
            document.documentElement.requestFullscreen().catch(() => {
            });
        }
        setIsFullScreen((prev) => !prev);
    };

    const handleVideoFirstFrame = (e: any) => {
        const video = e.target;
        video.disablePictureInPicture = true;
        setVideoSize({
            width: video.videoWidth,
            height: video.videoHeight
        });
    }

    const calcMiniMapSize = () => {
        let size = miniMapSize;
        if (isFullScreen) {
            size *= 2;
        }
        return size;
    }

    const closedView = () => <></>;
    const containerClasses = () => {
        let className = classes.container;
        if (isClosed) {
            return className;
        }
        if (isFullScreen) {
            className += ` ${classes.containerFull}`;
        } else {
            className += ` ${classes.containerMinimized}`;
        }
        return className;
    }
    const openedView = () => (
        <div className={classes.playerContainer}>
            <div className={classes.toolbar}>
                <div className={classes.button} onClick={closeVideo}>
                    <CloseIcon className={classes.icon}/>
                </div>
                <div className={classes.button} onClick={toggleFullScreen}>
                    {isFullScreen && <FullScreenExit className={classes.icon}/>}
                    {!isFullScreen && <FullScreen className={classes.icon}/>}
                </div>
            </div>
            {playedEvent && <video ref={videoRef} className={classes.player} controls={isShowControls}
                                   autoPlay={isAutoPlay} loop={isLoop} onLoadedMetadata={handleVideoFirstFrame}
                                   onPause={pause} onPlay={play} onResetCapture={reset}
                                   onTimeUpdate={timelineUpdate} controlsList='nodownload noplaybackrate nofullscreen'>
                <source src={playedEvent.videoUrl} type="video/mp4"/>
            </video>}
            {showMiniMap(wheelSectors, wheelObstacle, calcMiniMapSize(), classes.miniMap, true)}
        </div>

    );


    return (

        <div className={containerClasses()}>
            {isClosed && closedView()}
            {!isClosed && openedView()}

        </div>


    )
}

export default VideoPlayer;
