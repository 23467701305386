export enum SortBy{
	time,
	type,
	severity,
	vehicle
}

export enum FeatureType{
	empty,
	vehicle,
	safetyEvent,
	hazard,
	obstacle,
}

export enum Falling {
	No = -1,
	Ready = 0,
	Alert = 1
}