import React from 'react';
import useStyles from './verticalReverseAlertBar.css';
import { useSelector, useDispatch } from 'react-redux';
import AlertBox from '../reverseAlertBar/alertBox/AlertBox';
import { getAlertSwitches, getIsBermVisible, getReverseData } from '../../../store/selectors/mining/mining.selectors';
import { flipAlertSwitch } from '../../../store/actions/mining.actions';
import profileConfig from "../../../core/profiles/profileConfig";

function ReverseAlertBar() {
	const classes = useStyles();
	const reverseData = useSelector(getReverseData);
	const isBermVisible = useSelector(getIsBermVisible);
	const alertSwitches = useSelector(getAlertSwitches);
	const dispatch = useDispatch();
	const flipSoundSwitch = (selectedAlert) => () => {
		if(!!profileConfig().disableReverseVolumeControl) return;
		dispatch(flipAlertSwitch(selectedAlert));
	};

	return (
		<div className={classes.root}>
			<AlertBox disabled={!isBermVisible} isAlert={reverseData.SpeedAlert > 0} label={'SD'}
					  isSoundOn={alertSwitches.speedAlert} soundSwitch={flipSoundSwitch('speedAlert')} short={true}/>
			<AlertBox disabled={!isBermVisible} isAlert={reverseData.ApproachAngleAlert > 0} label={'AA'}
					  isSoundOn={alertSwitches.approachAngleAlert}
					  soundSwitch={flipSoundSwitch('approachAngleAlert')} short={true} />
			<AlertBox disabled={!isBermVisible} isAlert={reverseData.MidAxleHeightAlert > 0} label={'BH'}
					  isSoundOn={alertSwitches.midAxleHeightAlert}
					  soundSwitch={flipSoundSwitch('midAxleHeightAlert')} short={true} />
		</div>
	);
}

export default ReverseAlertBar;
