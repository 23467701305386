import { convertTimeToText } from '../../auxilary/formatting';
import { convertWgs84ToWebMercator } from '../projections';
import { getSafetyEventIcon } from '../icons/safetyEventIcons';
import { config } from '../../../services/config';

export const getSafetyEventLabel = (type, description) => {
	if (type === 0 && description) return description;
	const safetyEventLabels = config.hazardAndSafetyLabels.safeties;
	if (safetyEventLabels[type]) return safetyEventLabels[type];
	return safetyEventLabels[0];
};

export const phraseSafetyEventsToAlerts = safetyEvents =>
	safetyEvents.map(safetyEvent => {
		return {
			label: getSafetyEventLabel(
				safetyEvent.type_of_event,
				safetyEvent.event_description
			),
			symbol: getSafetyEventIcon(
				safetyEvent.type_of_event,
				safetyEvent.severity
			),
			date: convertTimeToText(safetyEvent.creation_time),
			id: safetyEvent.id,
			position: convertWgs84ToWebMercator([
				safetyEvent.longitude,
				safetyEvent.latitude,
			]),
		};
	});

export const getHazardLabel = (type, description) => {
	if (type === 0 && description) return description;
	const obstacleLabels = config.hazardAndSafetyLabels.hazards;
	if (obstacleLabels[type]) return obstacleLabels[type];
	return obstacleLabels[0];
};
