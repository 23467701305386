import { config } from "../../../services/config";

const getImportance = (type: string, severity: number) => {
	const importanceMatrix = config.importanceMatrix;
	const typeImportance = importanceMatrix[type];
	if(!typeImportance) return 0;
	return typeImportance[severity] || 0;
}

export const getAlertStringFromUnloading = ({reverse, speed, angle, berm, zone, lane}) => {
	const alert = ['null', 0];
	if(reverse){
		const speedImportance = getImportance('speed', speed);
		const angleImportance = getImportance('angle', angle);
		const bermImportance = getImportance('berm',  berm);
		[alert[0], alert[1]] = [`speed_${speed}`, speedImportance];
		if(angleImportance > alert[1]) [alert[0], alert[1]] = [`angle_${angle}`, angleImportance];
		if(bermImportance > alert[1]) [alert[0], alert[1]] = [`berm_${berm}`, bermImportance];
	}else{
		const zoneImportance = getImportance('speed', zone ? 1 :0);
		const laneImportance = getImportance('speed', lane ? 1 :0);
		if(zoneImportance > alert[1]) [alert[0], alert[1]] = ['zone_1', zoneImportance];
		if(laneImportance > alert[1]) [alert[0], alert[1]] = ['lane_1', laneImportance];
	}
	return alert;
};
