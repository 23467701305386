import produce from 'immer';
import {IDashboardLoadingAnErrorPayload, MiningActionTypes} from '../../actions/mining.actions';
import { DashboardPeriods, DASHBOARD_OVERALL_OPTION, IMiningReducerState } from './IMiningReducerState';
import miningReducerInitState from './initState';
import { pointInBbox } from '../../../core/services/gis';
import { isSeverityZeroWarn } from '../../../definition/severity';

export const miningReducer = produce(
	(
		state: IMiningReducerState,
		action,
	) => {
		switch (action.type) {
			case MiningActionTypes.FALLING:
				state.isFalling = action.payload;
				return;

			case MiningActionTypes.SET_TRUCK_STATUS:
				state.isGoingReverse = action.payload.isGoingReverse;
				state.isBermVisible = action.payload.isBermVisible;
				if(action.payload.isBermVisible && action.payload.reverseData){
					state.reverseData.SpeedAlert = action.payload.reverseData.SpeedAlert;
					state.reverseData.ApproachAngleAlert = action.payload.reverseData.ApproachAngleAlert;
					state.reverseData.MidAxleHeightAlert = action.payload.reverseData.MidAxleHeightAlert;
					state.reverseData.SpeedKMH = action.payload.reverseData.SpeedKMH;
					state.reverseData.DistanceToBermCm = action.payload.reverseData.DistanceToBermCm;
					state.reverseData.ApproachAngle = action.payload.reverseData.ApproachAngle;
					state.reverseData.BermHeightCM = action.payload.reverseData.BermHeightCM;
				}
				if (!action.payload.isGoingReverse) {
					state.reverseData.SpeedAlert = 0;
					state.reverseData.ApproachAngleAlert = 0;
					state.reverseData.MidAxleHeightAlert = 0;
				}
				// // clear obstacles if going reverse.
				// if (action.payload.isGoingReverse) {
				// 	state.obstacles = [];
				// }
				return;

			case MiningActionTypes.FLIP_ALERT_SWITCH:
				state.alertSwitches[action.payload] = !state.alertSwitches[action.payload];
				return;

			case MiningActionTypes.SET_OBSTACLE_ALERTS:
				state.obstacles = action.payload;
				return;

			case MiningActionTypes.SET_WARNING_SECTIONS:
				state.warningSectors = action.payload;
				return;

			case MiningActionTypes.CLOSE_TOOLTIP:
				state.tooltips.obstacle.isOpen = false;
				return;

			case MiningActionTypes.SET_TOOLTIP:
				state.tooltips.obstacle = action.payload;
				return;

			case MiningActionTypes.SET_VEHICLE_TOOLTIP:
				state.tooltips.obstacle.isOpen = false;
				state.tooltips.vehicle = action.payload;
				return;

			case MiningActionTypes.SET_MINECEPT_STATUS:
				state.mineceptStatus = action.payload;
				return;

			case MiningActionTypes.TOGGLE_LAYER_DISPLAY:
				const mineceptLayers = { ...state.mineceptLayers };
				mineceptLayers[action.payload.layerId] = !mineceptLayers[action.payload.layerId];
				state.mineceptLayers = {
					...mineceptLayers,
				};
				return;

			case MiningActionTypes.SET_FORWARD_VIEW:
				state.isGoingReverse = false;
				state.reverseData.SpeedAlert = 0;
				state.reverseData.ApproachAngleAlert = 0;
				state.reverseData.MidAxleHeightAlert = 0;
				return;

			case MiningActionTypes.SET_SAFETY_EVENTS:
				state.safetyEvents = action.payload;
				return;

			case MiningActionTypes.ADD_SAFETY_EVENT:
				state.safetyEvents.push(...action.payload);
				state.flags.newSafetyEvent = true;
				return;

			case MiningActionTypes.REMOVE_SAFETY_EVENTS:
				if (state.controls.safetyEvents.filters.archive) {
					state.safetyEvents = state.safetyEvents.map(event => {
						if (!action.payload.includes(event.id)) {
							return event;
						}
						return {
							...event,
							is_active: false,
						};
					});
					return;
				}

				state.safetyEvents = [
					...state.safetyEvents.filter(event => !action.payload.includes(event.id)),
				];
				return;

			case MiningActionTypes.RESTORE_SAFETY_EVENTS:
				const newEventIds = [...action.payload.map(event => event.id)];
				state.safetyEvents = [
					...state.safetyEvents.filter(safetyEvent => !newEventIds.includes(safetyEvent.id)),
					...action.payload,
				];
				return;

			case MiningActionTypes.UPDATE_SAFETY_EVENT:
				state.safetyEvents = [
					...state.safetyEvents.filter(safetyEvent => safetyEvent.id !== action.payload.id),
					{ ...action.payload },
				];
				return;

			case MiningActionTypes.CLEAR_SAFETY_EVENTS:
				state.safetyEvents = [];
				state.flags.newSafetyEvent = false;
				return;

			case MiningActionTypes.SET_HAZARDS:
				state.hazards = action.payload;
				return;

			case MiningActionTypes.ADD_HAZARDS:
				state.hazards.push(...action.payload);
				state.flags.newHazard = true;
				return;

			case MiningActionTypes.REMOVE_HAZRADS:
				if (state.controls.hazards.filters.archive) {
					state.hazards = state.hazards.map(hazard => {
						if (!action.payload.includes(hazard.id)) {
							return hazard;
						}
						return {
							...hazard,
							is_active: false,
						};
					});
					return;
				}

				state.hazards = [
					...state.hazards.filter(hazard => !action.payload.includes(hazard.id)),
				];
				return;

			case MiningActionTypes.RESTORE_HAZRADS:
				const newHazardIds = [...action.payload.map(hazard => hazard.id)];
				state.hazards = [
					...state.hazards.filter(hazard => !newHazardIds.includes(hazard.id)),
					...action.payload,
				];
				return;

			case MiningActionTypes.UPDATE_HAZARD:
				const idsToUpdate = action.payload.map(hazard => hazard.id);
				state.hazards = [
					...state.hazards.filter(hazard => !idsToUpdate.includes(hazard.id)),
					...action.payload,
				];
				return;

			case MiningActionTypes.CLEAR_HAZARDS:
				state.hazards = [];
				state.flags.newHazard = false;
				return;

			case MiningActionTypes.SET_NEW_SAFETY_EVENT_FLAG:
				state.flags.newSafetyEvent = !!action.payload;
				return;

			case MiningActionTypes.SET_NEW_HAZARD_FLAG:
				state.flags.newHazard = !!action.payload;
				return;

			case MiningActionTypes.SELECT:
				state.selected = action.payload;
				return;

			case MiningActionTypes.SET_SAFETY_EVENT_SORT:
				state.controls.safetyEvents.sortBy = action.payload;
				return;

			case MiningActionTypes.SET_HAZARD_SORT:
				state.controls.hazards.sortBy = action.payload;
				return;

			case MiningActionTypes.SET_EVENT_FILTERS:
				state.controls.safetyEvents.filters = action.payload;
				return;

			case MiningActionTypes.RESET_EVENT_FILTERS:
				state.controls.safetyEvents.filters.type = [...Array(100).keys()];
				state.controls.safetyEvents.filters.severity = isSeverityZeroWarn ? [0, 1, 2, 3] : [1, 2, 3];
				state.controls.safetyEvents.filters.filterStart = false;
				state.controls.safetyEvents.filters.filterEnd = false;
				state.controls.safetyEvents.filters.archive = false;
				state.controls.safetyEvents.filters.filterVehicles = false;
				state.controls.safetyEvents.filters.vehicles = [];
				return;

			case MiningActionTypes.SET_HAZARD_FILTERS:
				state.controls.hazards.filters = action.payload;
				return;

			case MiningActionTypes.RESET_HAZARD_FILTERS:
				state.controls.hazards.filters.type = [...Array(100).keys()];
				state.controls.hazards.filters.severity = isSeverityZeroWarn ? [0, 1, 2, 3] : [1, 2, 3];
				state.controls.hazards.filters.filterStart = false;
				state.controls.hazards.filters.filterEnd = false;
				state.controls.hazards.filters.archive = false;
				state.controls.hazards.filters.filterVehicles = false;
				state.controls.hazards.filters.vehicles = [];
				return;
			case MiningActionTypes.MULTI_SELECT:
				state.multiSelect.push(action.payload);
				return;
			case MiningActionTypes.MULTI_UNSELECT:
				state.multiSelect= state.multiSelect.filter(id=>id !== action.payload);
				return;
			case MiningActionTypes.MULTI_SELECT_ALL_SAFETY_EVENT:
				state.multiSelect=[
					...state.safetyEvents.map(event=>event.id)
				];
				return
			case MiningActionTypes.MULTI_SELECT_ALL_HAZARDS:
				state.multiSelect=[
					...state.hazards.map(hazard=>hazard.id)
				];
				return
			case MiningActionTypes.MULTI_UNSELECT_ALL_HAZARDS:
				const hazardIds = state.hazards.map(hazard => hazard.id);
				state.multiSelect=state.multiSelect.filter(selected => !hazardIds.includes(selected));
				return
			case MiningActionTypes.MULTI_UNSELECT_ALL_SAFETY_EVENT:
				const eventIds = state.safetyEvents.map(event => event.id);
				state.multiSelect=state.multiSelect.filter(selected => !eventIds.includes(selected));
				return
			case MiningActionTypes.CLEAR_MULTI_SELECT:
				state.multiSelect=[];
				return
			case MiningActionTypes.MULTI_SELECT_IN_BBOX:
				const features = [...state.hazards,...state.safetyEvents];
				state.multiSelect = features.filter(feature =>pointInBbox([feature.longitude,feature.latitude],action.payload))
					.map(feature => feature.id);
				return
			case MiningActionTypes.SET_PLAYED_EVENT:
				state.playedEvent = action.payload;
				return;
			case MiningActionTypes.SET_SITES:
				state.multiSite = action.payload;
				clearSiteState(state);
				return;
			case MiningActionTypes.SET_SELECTED_SITE:
				if (state.multiSite.selectedSite !== action.payload) {
					state.multiSite.selectedSite = action.payload;
					clearSiteState(state);
				}
				return;
			case MiningActionTypes.SET_DASHBOARD_DATA:
				const { site, periodDays, data  } = action.payload;
				state.dashboard.site = site;
				state.dashboard.period = periodDays;
				state.dashboard.data = data;
				return ;
			case MiningActionTypes.RESET_DASHBOARD_DATA:
				state.dashboard.site = DASHBOARD_OVERALL_OPTION;
				state.dashboard.period = DashboardPeriods.Day;
				state.dashboard.data = undefined;
				return;
			case MiningActionTypes.SET_DASHBOARD_IS_LOADING_AND_ERROR:
				const { isLoading, isError }: IDashboardLoadingAnErrorPayload = action.payload;
				state.dashboard.isLoading = isLoading;
				state.dashboard.isError = isError;
				return;
		}
	},
	miningReducerInitState,
);

const clearSiteState = (state: IMiningReducerState) => {
	state.hazards = [];
	state.safetyEvents = [];
	state.tooltips = {
		obstacle: {
			isOpen: false,
			severity: 0,
			obstacleType: 0,
			obstacleDescription: '',
			position: [0, 0],
		}
	};
	state.selected = null;
	state.multiSelect = [];
	state.flags = {
		newSafetyEvent: false,
		newHazard: false,
	};
	state.playedEvent = undefined;
};

export default miningReducer;
