import React from 'react';
import {getSeverityColors, noActiveColor, noAlertColor} from '../../../definition/severity';
import Haul from './icons/Haul';
import WheelLoader from './icons/WheelLoader';
import profileConfig from '../../../core/profiles/profileConfig';
import {isShowAlertsInWheel} from '../../../services/obstacles';

const obstacleMinSeverityToAlert = parseInt(process.env.REACT_APP_OBSTACLE_MINIMUM_SEVERITY_TO_ALERT as string);

const severityColors = Array(4).fill('').map((_, i) =>
    i < obstacleMinSeverityToAlert ? noAlertColor : getSeverityColors(i).icon);
const warningColors = [
    noActiveColor,
    ...severityColors
];

export interface IObstacleMiniMap {
    severity: number
    obstacleType: number
    obstacleDescription?: string
}

interface IProps {
    size: number,
    sectors: Array<number>,
    obstacles: Array<IObstacleMiniMap>
}

const centerIcons = new Map([
    ['haul', Haul],
    ['wheelLoader', WheelLoader]
]);
const showVehicle = () => {
    const appShowVehicle = process.env.REACT_APP_SHOW_VEHICLE;
    const isShowVehicle = appShowVehicle && appShowVehicle.trim().toLowerCase();
    return isShowVehicle && isShowVehicle === 'true';
};


function MinMap({size, sectors, obstacles}: IProps) {
    const CenterIcon = centerIcons.get(profileConfig().miniMapIcon) || Haul;
    const isShowAlert = isShowAlertsInWheel && obstacles && obstacles.length > 0 && (obstacles.some(x => x.severity >= obstacleMinSeverityToAlert));

    return (
        <svg width={size} height={size} viewBox="0 0 222 228" version="1.1">
            <defs>
                <circle id="path-1" cx="105.211366" cy="105.20835" r="105"/>
                <filter x="-5.7%" y="-5.7%" width="111.4%" height="114.3%" filterUnits="objectBoundingBox"
                        id="filter-2">
                    <feOffset dx="0" dy="6" in="SourceAlpha" result="shadowOffsetOuter1"/>
                    <feGaussianBlur stdDeviation="3" in="shadowOffsetOuter1" result="shadowBlurOuter1"/>
                    <feComposite in="shadowBlurOuter1" in2="SourceAlpha" operator="out" result="shadowBlurOuter1"/>
                    <feColorMatrix values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.5 0" type="matrix"
                                   in="shadowBlurOuter1" result="shadowMatrixOuter1"/>
                    <feOffset dx="0" dy="0" in="SourceAlpha" result="shadowOffsetOuter2"/>
                    <feGaussianBlur stdDeviation="3" in="shadowOffsetOuter2" result="shadowBlurOuter2"/>
                    <feComposite in="shadowBlurOuter2" in2="SourceAlpha" operator="out" result="shadowBlurOuter2"/>
                    <feColorMatrix values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.5 0" type="matrix"
                                   in="shadowBlurOuter2" result="shadowMatrixOuter2"/>
                    <feMerge>
                        <feMergeNode in="shadowMatrixOuter1"/>
                        <feMergeNode in="shadowMatrixOuter2"/>
                    </feMerge>
                </filter>
                <filter x="-3.8%" y="-3.8%" width="107.6%" height="110.5%" filterUnits="objectBoundingBox"
                        id="filter-3">
                    <feGaussianBlur stdDeviation="1.5" in="SourceAlpha" result="shadowBlurInner1"/>
                    <feOffset dx="0" dy="1" in="shadowBlurInner1" result="shadowOffsetInner1"/>
                    <feComposite in="shadowOffsetInner1" in2="SourceAlpha" operator="arithmetic" k2="-1" k3="1"
                                 result="shadowInnerInner1"/>
                    <feColorMatrix
                        values="0 0 0 0 0.868546196   0 0 0 0 0.858897521   0 0 0 0 0.858897521  0 0 0 0.519640516 0"
                        type="matrix" in="shadowInnerInner1"/>
                </filter>
                <linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="linearGradient-4">
                    <stop stopColor="#88EDFF" offset="0%"/>
                    <stop stopColor="#2A97BA" offset="100%"/>
                </linearGradient>
                <linearGradient x1="69.0521244%" y1="41.3286224%" x2="34.2286828%" y2="63.0144015%"
                                id="linearGradient-5">
                    <stop stopColor="#6F6F6F" stopOpacity="0.561461976" offset="0%"/>
                    <stop stopColor="#FFFFFF" stopOpacity="0" offset="100%"/>
                </linearGradient>
                <linearGradient x1="100%" y1="50%" x2="10.3456058%" y2="50%" id="linearGradient-6">
                    <stop stopColor="#6F6F6F" stopOpacity="0.561461976" offset="0%"/>
                    <stop stopColor="#FFFFFF" stopOpacity="0" offset="100%"/>
                </linearGradient>
                <linearGradient x1="70.1679746%" y1="59.001765%" x2="32.3955846%" y2="34.0641243%"
                                id="linearGradient-7">
                    <stop stopColor="#6F6F6F" stopOpacity="0.561461976" offset="0%"/>
                    <stop stopColor="#FFFFFF" stopOpacity="0" offset="100%"/>
                </linearGradient>
                <linearGradient x1="61.2909375%" y1="68.4422698%" x2="34.0161824%" y2="32.6237306%"
                                id="linearGradient-8">
                    <stop stopColor="#6F6F6F" stopOpacity="0.561461976" offset="0%"/>
                    <stop stopColor="#FFFFFF" stopOpacity="0" offset="100%"/>
                </linearGradient>
                <linearGradient x1="50%" y1="100%" x2="50%" y2="11.9490275%" id="linearGradient-9">
                    <stop stopColor="#6F6F6F" stopOpacity="0.561461976" offset="0%"/>
                    <stop stopColor="#FFFFFF" stopOpacity="0" offset="100%"/>
                </linearGradient>
                <linearGradient x1="30.1774876%" y1="59.9901495%" x2="67.7585827%" y2="34.2495271%"
                                id="linearGradient-10">
                    <stop stopColor="#6F6F6F" stopOpacity="0.561461976" offset="0%"/>
                    <stop stopColor="#FFFFFF" stopOpacity="0" offset="100%"/>
                </linearGradient>
                <linearGradient x1="32.1273551%" y1="63.3697107%" x2="69.2513452%" y2="35.9657936%"
                                id="linearGradient-11">
                    <stop stopColor="#6F6F6F" stopOpacity="0.561461976" offset="0%"/>
                    <stop stopColor="#FFFFFF" stopOpacity="0" offset="100%"/>
                </linearGradient>
                <linearGradient x1="0%" y1="50%" x2="89.1318017%" y2="50%" id="linearGradient-12">
                    <stop stopColor="#6F6F6F" stopOpacity="0.561461976" offset="0%"/>
                    <stop stopColor="#FFFFFF" stopOpacity="0" offset="100%"/>
                </linearGradient>
                <linearGradient x1="31.8947714%" y1="41.7044199%" x2="65.3647058%" y2="66.3999784%"
                                id="linearGradient-13">
                    <stop stopColor="#6F6F6F" stopOpacity="0.561461976" offset="0%"/>
                    <stop stopColor="#FFFFFF" stopOpacity="0" offset="100%"/>
                </linearGradient>
                <linearGradient x1="39.9695543%" y1="32.0176064%" x2="64.2321974%" y2="68.0067928%"
                                id="linearGradient-14">
                    <stop stopColor="#6F6F6F" stopOpacity="0.561461976" offset="0%"/>
                    <stop stopColor="#FFFFFF" stopOpacity="0" offset="100%"/>
                </linearGradient>
                <linearGradient x1="50%" y1="0%" x2="50%" y2="92.3977632%" id="linearGradient-15">
                    <stop stopColor="#6F6F6F" stopOpacity="0.561461976" offset="0%"/>
                    <stop stopColor="#FFFFFF" stopOpacity="0" offset="100%"/>
                </linearGradient>
                <linearGradient x1="58.5154729%" y1="31.0841151%" x2="34.7600217%" y2="65.992003%"
                                id="linearGradient-16">
                    <stop stopColor="#6F6F6F" stopOpacity="0.561461976" offset="0%"/>
                    <stop stopColor="#FFFFFF" stopOpacity="0" offset="100%"/>
                </linearGradient>
            </defs>
            <g id="meatball" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="BG" transform="translate(5.829444, 5.832574)">
                    <g id="Oval">
                        <use fill="black" fillOpacity="1" filter="url(#filter-2)" xlinkHref="#path-1"/>
                        <use fillOpacity="0.9" fill="#25334B" fillRule="evenodd" xlinkHref="#path-1"/>
                        <use fill="black" fillOpacity="1" filter="url(#filter-3)" xlinkHref="#path-1"/>
                        <circle stroke="#25334B" strokeWidth="1" strokeLinecap="square" cx="105.211366" cy="105.20835"
                                r="104.5"/>
                    </g>
                    <path
                        d="M105,0 C47.1024924,0 0,47.1024924 0,105 C0,162.894479 47.1024924,210 105,210 C162.897508,210.003029 210,162.897508 210,105 C210,47.1055213 162.897508,0 105,0 Z M106.545727,209.036697 L103.454273,209.036697 C47.294922,208.214383 1.78872094,162.705766 0.963302752,106.54725 L0.963302752,103.455842 C1.78562948,47.297325 47.294922,1.78870867 103.454273,0.963302752 L106.545727,0.963302752 C162.705078,1.78870867 208.211279,47.2942336 209.036697,103.45275 L209.036697,106.544158 C208.214371,162.705766 162.705078,208.214383 106.545727,209.036697 Z"
                        id="Combined-Shape" fill="url(#linearGradient-4)" fillRule="nonzero"></path>
                </g>
                <g id="Truck" transform="translate(74.335254, 54.450463)" opacity="0.814407703">
                    {showVehicle() && !isShowAlert && <CenterIcon/>}
                </g>
                <path fill={warningColors[sectors[0]]}
                      d="M86.7011943,14.1703689 C102.856362,9.97286202 119.345044,10.1973012 136.030076,14.5213269 L129.867408,37.2923848 C118.104925,34.4908774 105.298708,34.0464761 92.8879604,36.9320752 L86.7011943,14.1703689 Z"
                      id="Front0" fillRule="nonzero"></path>
                <path fill={warningColors[sectors[1]]}
                      d="M138.421817,15.1867938 C155.419053,20.2323422 169.519101,29.0481432 181.428571,40.5569246 L164.611958,57.5007158 C155.633178,48.662604 144.963712,41.8563717 132.176502,37.9351021 L138.421817,15.1867938 Z"
                      id="Right30" fillRule="nonzero"></path>
                <path fill={warningColors[sectors[2]]}
                      d="M183.142622,42.2981364 C194.16436,54.3800322 202.478602,69.0977107 206.651932,85.3838427 L183.98702,91.5992757 C180.329539,77.8479302 173.851939,67.8251622 166.277694,59.2579883 L183.142622,42.2981364 Z"
                      id="Right60" fillRule="nonzero"></path>
                <path fill={warningColors[sectors[3]]}
                      d="M184.57592,94.0094376 L207.304239,87.7250932 C211.413711,104.670343 210.401832,123.856648 206.446594,136.998204 L183.707221,130.776069 C186.311619,118.706845 187.414942,106.481536 184.57592,94.0094376 Z"
                      id="Right90" fillRule="nonzero"></path>
                <path fill={warningColors[sectors[4]]}
                      d="M182.923512,133.135178 L205.762404,139.380529 C200.933196,156.622942 191.379876,169.751693 181.311122,180.552641 L164.714025,163.720179 C173.020476,154.792942 179.40802,144.749282 182.923512,133.135178 Z"
                      id="Right120" fillRule="nonzero"></path>
                <path fill={warningColors[sectors[5]]}
                      d="M162.997196,165.39205 L179.584958,182.273998 C167.668196,193.820712 151.023965,203.234034 138.896445,205.889768 L132.706807,183.134846 C144.453841,179.302425 154.571439,173.457655 162.997196,165.39205 Z"
                      id="Right150" fillRule="nonzero"></path>
                <path fill={warningColors[sectors[6]]}
                      d="M92.3121681,184.161145 C104.715211,187.243593 117.433411,186.703032 130.321011,183.801759 L136.506229,206.554832 C120.545086,211.00384 102.651124,211.248419 86.1309461,206.943621 L92.3121681,184.161145 Z"
                      id="Back180" fillRule="nonzero"></path>
                <path fill={warningColors[sectors[7]]}
                      d="M71.4125282,164.401016 L48.5248415,170.545343 C53.5037196,186.174522 60.4344451,199.862802 72.5248795,211.317242 L89.6817698,194.889277 C80.5591127,185.434086 74.9736104,175.254048 71.4125282,164.401016 Z"
                      id="Left210" fillRule="nonzero"
                      transform="translate(69.103306, 187.859129) rotate(-30.000000) translate(-69.103306, -187.859129) "></path>
                <path fill={warningColors[sectors[8]]}
                      d="M38.6446949,134.205374 L15.7588403,140.514437 C20.7377184,156.143616 28.7751742,169.994443 40.8656086,181.448882 L57.5452691,164.70914 C48.4226119,155.253949 42.2057771,145.058406 38.6446949,134.205374 Z"
                      id="Left240" fillRule="nonzero"></path>
                <path fill={warningColors[sectors[9]]}
                      d="M14.0497151,86.7717925 L36.9040471,93.0520667 C35.4126042,100.613649 34.7261621,106.677039 34.844721,111.242236 C34.9632798,115.807433 35.9773169,122.677483 37.8868322,131.852389 L15.1417193,138.142473 C10.3678979,121.390646 8.99680945,104.017157 14.0497151,86.7717925 Z"
                      id="Left270" fillRule="nonzero"></path>
                <path fill={warningColors[sectors[10]]}
                      d="M40.1289544,40.5569246 L56.6544863,57.5186779 C47.3068903,67.016238 41.3940466,78.3609039 37.5892894,90.6799648 L14.7626229,84.4610016 C19.4082142,68.2598652 27.173284,53.3420012 40.1289544,40.5569246 Z"
                      id="Left300" fillRule="nonzero"></path>
                <path fill={warningColors[sectors[11]]}
                      d="M41.7635856,38.9952698 C53.2255622,28.0375668 67.0125281,19.0651299 84.3704439,14.8789429 L90.5058011,37.693294 C77.1324057,41.218334 67.2453185,47.9395337 58.450479,55.6620785 L41.7635856,38.9952698 Z"
                      id="Left330" fillRule="nonzero"></path>


                <path
                    d="M183.142622,42.2981364 C194.16436,54.3800322 202.478602,69.0977107 206.651932,85.3838427 L183.98702,91.5992757 C180.329539,77.8479302 173.851939,67.8251622 166.277694,59.2579883 L183.142622,42.2981364 Z"
                    id="overlay_Right60" fill="url(#linearGradient-5)" fillRule="nonzero"></path>
                <path
                    d="M184.57592,94.0094376 L207.304239,87.7250932 C211.413711,104.670343 210.401832,123.856648 206.446594,136.998204 L183.707221,130.776069 C186.311619,118.706845 187.414942,106.481536 184.57592,94.0094376 Z"
                    id="overlay_Right90" fill="url(#linearGradient-6)" fillRule="nonzero"></path>
                <path
                    d="M182.923512,133.135178 L205.762404,139.380529 C200.933196,156.622942 191.379876,169.751693 181.311122,180.552641 L164.714025,163.720179 C173.020476,154.792942 179.40802,144.749282 182.923512,133.135178 Z"
                    id="overlay_Right120" fill="url(#linearGradient-7)" fillRule="nonzero"></path>
                <path
                    d="M162.997196,165.39205 L179.584958,182.273998 C167.668196,193.820712 151.023965,203.234034 138.896445,205.889768 L132.706807,183.134846 C144.453841,179.302425 154.571439,173.457655 162.997196,165.39205 Z"
                    id="overlay_Right150" fill="url(#linearGradient-8)" fillRule="nonzero"></path>
                <path
                    d="M92.3121681,184.161145 C104.715211,187.243593 117.433411,186.703032 130.321011,183.801759 L136.506229,206.554832 C120.545086,211.00384 102.651124,211.248419 86.1309461,206.943621 L92.3121681,184.161145 Z"
                    id="overlay_Back180" fill="url(#linearGradient-9)" fillRule="nonzero"></path>
                <path
                    d="M71.4125282,164.401016 L48.5248415,170.545343 C53.5037196,186.174522 60.4344451,199.862802 72.5248795,211.317242 L89.6817698,194.889277 C80.5591127,185.434086 74.9736104,175.254048 71.4125282,164.401016 Z"
                    id="overlay_Left210" fill="url(#linearGradient-10)" fillRule="nonzero"
                    transform="translate(69.103306, 187.859129) rotate(-30.000000) translate(-69.103306, -187.859129) "></path>
                <path
                    d="M38.6446949,134.205374 L15.7588403,140.514437 C20.7377184,156.143616 28.7751742,169.994443 40.8656086,181.448882 L57.5452691,164.70914 C48.4226119,155.253949 42.2057771,145.058406 38.6446949,134.205374 Z"
                    id="overlay_Left240" fill="url(#linearGradient-11)" fillRule="nonzero"></path>
                <path
                    d="M14.0497151,86.7717925 L36.9040471,93.0520667 C35.4126042,100.613649 34.7261621,106.677039 34.844721,111.242236 C34.9632798,115.807433 35.9773169,122.677483 37.8868322,131.852389 L15.1417193,138.142473 C10.3678979,121.390646 8.99680945,104.017157 14.0497151,86.7717925 Z"
                    id="overlay_Left270" fill="url(#linearGradient-12)" fillRule="nonzero"></path>
                <path
                    d="M40.1289544,40.5569246 L56.6544863,57.5186779 C47.3068903,67.016238 41.3940466,78.3609039 37.5892894,90.6799648 L14.7626229,84.4610016 C19.4082142,68.2598652 27.173284,53.3420012 40.1289544,40.5569246 Z"
                    id="overlay_Left300" fill="url(#linearGradient-13)" fillRule="nonzero"></path>
                <path
                    d="M41.7635856,38.9952698 C53.2255622,28.0375668 67.0125281,19.0651299 84.3704439,14.8789429 L90.5058011,37.693294 C77.1324057,41.218334 67.2453185,47.9395337 58.450479,55.6620785 L41.7635856,38.9952698 Z"
                    id="overlay_Left330" fill="url(#linearGradient-14)" fillRule="nonzero"></path>
                <path
                    d="M86.7011943,14.1703689 C102.856362,9.97286202 119.345044,10.1973012 136.030076,14.5213269 L129.867408,37.2923848 C118.104925,34.4908774 105.298708,34.0464761 92.8879604,36.9320752 L86.7011943,14.1703689 Z"
                    id="overlay_Front0" fill="url(#linearGradient-15)" fillRule="nonzero"></path>
                <path
                    d="M138.421817,15.1867938 C155.419053,20.2323422 169.519101,29.0481432 181.428571,40.5569246 L164.611958,57.5007158 C155.633178,48.662604 144.963712,41.8563717 132.176502,37.9351021 L138.421817,15.1867938 Z"
                    id="overlay_Right30" fill="url(#linearGradient-16)" fillRule="nonzero"></path>
            </g>
        </svg>
    );
}

export default MinMap;
