import { makeStyles } from '@material-ui/core/styles';
import { getSeverityColors } from '../../definition/severity';

interface Props {
	size: number;
	severity: number;
	small: boolean;
	vertical: boolean;
}

const calcSymbolWidth = (size, small, vertical) => {
	if (small) return 70 * (size / 200);
	if (vertical) return size < 600 ? 190 : 350;
	return size < 500 ? 130 : 190;
};
const calcFontSize = (size, small, vertical) => {
	if (small) return 16 * (size / 200);
	if (vertical) return size < 600 ? 38 : 60;
	return size < 500 ? 30 : 38;
};

export default makeStyles({
	root: props => ({
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		position: 'absolute',
		left: 0,
		right: 0,
	}),
	label: (props: Props) => ({
		color: getSeverityColors(props.severity).icon,
		fontFamily: 'Mukta Mahee',
		fontSize: calcFontSize(props.size, props.small, props.vertical), //props.small ? 18 : 22,
		lineHeight: 1,
	}),

	symbol: props => ({
		width: calcSymbolWidth(props.size, props.small, props.vertical),
		objectFit: 'contain'
	}),
});
