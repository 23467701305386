import { Style, Text, Icon, Fill, Stroke} from 'ol/style';
import truckImage from '../../../../assets/images/activeUnselectedTruckNew.svg';
import selectedTruckImage from '../../../../assets/images/activeSelectedTruckNew.svg';
import inactiveTruckImage from '../../../../assets/images/inactiveUnselectedTruckNew.svg';
import inactiveSelectedTruckImage from '../../../../assets/images/inactiveSelectedTruckNew.svg';

const vehicleImages =[
	truckImage,
	selectedTruckImage,
	inactiveTruckImage,
	inactiveSelectedTruckImage
];

const textStyle = (feature, resolution) => new Text({
		textAlign: 'center',
		font: '400 20px/1 Mukta Mahee',
		text: `${feature.get('vehicleId')}`,
		fill: new Fill({color: 'white'}),
		stroke: new Stroke({color: 'black', width: 3}),
		offsetY: -40,
		// backgroundFill: new Fill({color: 'green'}),
});

const styleFunction = (selected) => (feature, resolution) => {
	let srcIndex = (!!feature.values_.inActive ? 2 : 0) + (selected ? 1 : 0);

	return new Style({
		image: new Icon({
			anchor: [0.5, 0.5],
			src: vehicleImages[srcIndex],
			// size: [30,30]
			scale: 0.7,
			rotation:feature.getProperties().rotation,
			rotateWithView: true
		}),
		text: textStyle(feature, resolution)
	});
};


export default {
	selected: styleFunction(true),
	unSelected: styleFunction(false),
};
