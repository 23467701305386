import { MapActionTypes } from '../actions/map.actions';
import { sourceMapOptions } from '../../definition/source-map-options';

export interface IMapState {
	followRoute: boolean;
	mapSources: sourceMapOptions[];
	mapRotation: number;
	staticState: boolean;
	audioStateOn: boolean;
}

export const MapInitialState = {
	followRoute: false,
	staticState: true,
	audioStateOn: true,
	mapSources: [ sourceMapOptions.SATELLITE ],
	mapRotation: 0
};

export default (state: IMapState = MapInitialState, action) => {
	let mapRotation = state.mapRotation;

	switch (action.type) {
		case MapActionTypes.TOOGLE_AUDIO_STATE:
			const audioStateOn = !state.audioStateOn;

			return {
				...state,
				audioStateOn
			};
		case MapActionTypes.TOGGLE_STATIC_STATE:
			const staticState = !state.staticState;

			return {
				...state,
				followRoute: false,
				staticState,
				mapRotation: 0
			};

		case MapActionTypes.SET_STATIC_STATE:
			return {
				...state,
				followRoute: false,
				staticState: action.payload,
				mapRotation: 0
			};

		case MapActionTypes.TOGGLE_FOLLOW_ROUTE:
			const followRoute = !state.followRoute;

			if (followRoute) {
				mapRotation = 0;
			}

			return {
				...state,
				followRoute,
				mapRotation
			};

		case MapActionTypes.SET_FOLLOW_ROUTE:
			return {
				...state,
				followRoute: action.payload
			};

		case MapActionTypes.ADD_MAP_SOURCE:
			return { ...state, mapSources: Array.from(new Set(state.mapSources.concat(action.payload))) };

		case MapActionTypes.REMOVE_MAP_SOURCE:
			return { ...state, mapSources: state.mapSources.filter((item) => item !== action.payload) };

		case MapActionTypes.ROTATION_CHANGED:
			return { ...state, mapRotation: action.payload };

		case MapActionTypes.CLEAR_ROTATION:
			return { ...state, mapRotation: 0 };
	}
	return state;
};
