import { makeStyles } from '@material-ui/core/styles';

// const viewportHeight = window.innerHeight;
interface Props{
	display:boolean
	archive:boolean
}
export default makeStyles({
	root: (props:Props) => ({
		transform:  `translate(${props.display? 94 : -500 }px,0px)`,
		position: 'absolute',
		width: 300,
		height: '100vh',
		// opacity: 0.92,
		// background: '#212e43',
		background: 'rgba(33,46,67,0.92)',
		transition: '0.5s',
	}),
	titleBar:{
		display: 'flex',
		justifyContent: 'space-between',
		padding: '10px 20px',
		width: '100%',
		height: 48,
		opacity: 0.92,
		background: '#485669',
	},
	closeButton:{
		cursor: 'pointer',
	},
	closeSymbol:{
		width: 15,
		height: 15,
	},
	list:()=>{
		const viewportHeight = window.innerHeight;
		return {
			maxHeight: viewportHeight -48 -48,
				overflow: 'auto',
		}
	}
});
