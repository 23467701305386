import React from 'react';
import { useSelector } from 'react-redux';
import useStyles from './fallingWarning.css';
import stop from '../../assets/images/stop.svg';
import warning from '../../assets/images/warning.svg';
import { getIsFalling } from '../../store/selectors/mining/mining.selectors';
import { Falling } from '../../definition/Enums';

function FallingWarning() {
	const classes = useStyles();
	const isFalling = useSelector(getIsFalling);
	if(isFalling !== Falling.Alert) return (<></>);
	return (
		<div className={classes.root}>
			<div className={classes.title}><img src={warning}/> DANGER <img src={warning}/></div>
			<div className={classes.subTitle}>FALLING OVER EDGE</div>
			<img className={classes.stop} src={stop}/>
		</div>
	);
}

export default FallingWarning;
