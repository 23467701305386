import React from 'react';
import { Bar } from 'react-chartjs-2';
import { formatDateRange } from '../../../../core/auxilary/formatting';
import { config } from '../../../../services/config';
import { getSeverityColor } from '../../../../services/icons-generator-service/icons-generator-service';

import { IMineceptDashboardSiteEventsBySeverity } from '../../../../store/reducers/mining/IMiningReducerState';
import { reverse } from '../../utils';

interface IProps {
	graph: IMineceptDashboardSiteEventsBySeverity;
	period: number;
}

const Chart = ({ graph, period }: IProps) => (
	<Bar
		options={{
			plugins: {
				title: {
					display: true,
					text: 'Safety events by severity over time',
				},
				datalabels: {
					display: config.general.dashboard.showDataLabels.siteEventsBySeverity,
					color: 'black',
				},
			},
			maintainAspectRatio: false,
			scales: {
				y: { beginAtZero: true, stacked: true },
			},
		}}
		data={{
			labels: reverse(graph.buckets).map(range =>
				formatDateRange(range, period === 1)
			),
			datasets: Object.entries(graph.data)
				.sort((a, b) => a[0].localeCompare(b[0]))
				.map(([severity, dataset]) => ({
					severity: parseInt(severity),
					dataset,
				}))
				.map(({ severity, dataset }) => ({
					label: `Severity ${severity}`,
					data: reverse(dataset),
					backgroundColor: getSeverityColor(severity),
					borderColor: getSeverityColor(severity),
					borderWidth: 1,
					stack: 'stack',
					barPercentage: 0.3,
				})),
		}}
	/>
);

export default Chart;
