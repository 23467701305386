import { makeStyles } from '@material-ui/core/styles';

export default makeStyles({
	root: {
		position: 'fixed',
		zIndex: 20000,
		width: '100%',
		height: '100%',
		border: '80px solid red',
		background: 'rgba(0,0,0,0.6)',
	},
	title: {
		position: 'relative',
		top: 'calc(15vh - 30px)',
		textAlign: 'center',
		color: 'red',
		fontSize: 61,
		fontFamily: 'Helvetica',
		fontWeight: 'bold',
	},
	subTitle: {
		position: 'relative',
		top: 'calc(15vh + 30px)',
		textAlign: 'center',
		color: 'red',
		fontSize: 46,
		fontFamily: 'Helvetica',
		fontWeight: 'bold',
	},
	stop: {
		display: 'block',
		marginLeft: 'auto',
		marginRight: 'auto',
		height: '40%',
		position: 'relative',
		top: 'calc(25%)',
	},
});
