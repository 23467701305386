import { makeStyles } from '@material-ui/core/styles';


export default makeStyles({
	root: (props:{menuOpen?:boolean}) => ({
		position: 'absolute',
		left: 120,
		// left: props.menuOpen ? 410 : 120,
		transform:  `translate(${props.menuOpen? 290 : 0 }px,0px)`,
		transition: '0.5s',
		top: 15,
		width: 270,
		// height: 528,
		padding: 22,
		borderRadius: 7,
		boxShadow: '0 2px 4px 0 rgba(178, 178, 178, 0.35), 0 2px 4px 0 rgba(149, 149, 149, 0.28)',
		background: '#ffffff',
	}),
	closeButton: {
		position: 'absolute',
		right: 14,
		top: 10,
		width: 12,
		height: 12,
		cursor: 'pointer',
	},
	symbol: {
		width: 32,
		height: 32,
	},
	label: {
		fontFamily: 'Mukta Mahee, Rubik',
		fontSize: 15,
		fontWeight: 'bold',
		color: '#505050',
		position: 'absolute',
		bottom: 20,
		left: 40,
		lineHeight: 1.2,
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		height: 32,
	},
	dataSection: {
		borderBottom: '1px solid black',
		paddingBottom: 15,
		margin: 15,
		position: 'relative',
	},
	dataField: {
		marginTop: 15
	},
	fieldTitle: {
		fontFamily: 'Mukta Mahee, Rubik',
		fontSize: 15,
		lineHeight: 0.5,
		color: '#3B3B3B',
		marginBottom: 8,
	},
	fieldValue: {
		fontFamily: 'Mukta Mahee, Rubik',
		wordWrap: 'break-word',
		fontSize: 15,
		fontWeight: 'bold',
		lineHeight: 1.5,
	},
	description: {
		paddingBottom: 10,
		paddingTop: 10,
		width: '100%',
	},
	descriptionText: {
		fontFamily: 'Mukta Mahee, Rubik',
		fontSize: 15,
		lineHeight: 1,
	},
	controls: {
		display: 'flex',
		justifyContent: 'space-between',
		margin: '0 15px',
	},
	control: {
		borderRadius: 12.5,
		border: '1px solid #2D3E78',
		padding: '1px 8px',
		cursor: 'pointer',
		fontSize: 14,
		display: 'flex',
		gap: 3,
		alignItems: 'center',
		justifyContent: 'space-between',
	},
	addDescriptionIcon:{
		marginLeft: '5px'
	},
	addDescription:{
		display: 'flex',
		alignItems: 'center',
		borderRadius: 12,
		cursor: 'pointer',
		width: 130,
		color: '#323232',
		fontSize: 14,
		fontFamily: 'Mukta Mahee, Rubik',
	},
	removeBox: {
		width: 356,
		height: 149,
		padding: '20px 17px',
		borderRadius: 12.5,
		boxShadow: '0 2px 4px 0 rgba(132, 125, 125, 0.5)',
		backgroundColor: '#ffffff',
	},
	removeTitle: {
		width: 254,
		height: 63,
		margin: '0 22px 12px 40px',
		fontFamily: 'Mukta Mahee, Rubik',
		fontSize: 17,
		fontWeight: 600,
		textAlign: 'center',
		color: '#2e2d46',
	},
	removeConfirm: {
		width: 130,
		height: 27,
		borderRadius: 12.5,
		lineHeight:'17px',
		margin: '0 15px',
		fontFamily: 'Mukta Mahee, Rubik',
		fontSize: 17,
		fontWeight: 500,
		textAlign: 'center',
		color: '#ffffff',
		backgroundColor: '#0f2365'
	},
	removeCancel: {
		width: 130,
		height: 27,
		borderRadius: 12.5,
		margin: '0 15px',
		fontFamily: 'Mukta Mahee, Rubik',
		fontSize: 17,
		lineHeight:'17px',
		fontWeight: 500,
		textAlign: 'center',
		color: '#0f2365',
	},
	descriptionCard: {
		position: 'absolute',
		top: 0,
		left: 300,
		width: 270,
		padding: 22,
		borderRadius: 7,
		boxShadow: '0 2px 4px 0 rgba(178, 178, 178, 0.35), 0 2px 4px 0 rgba(149, 149, 149, 0.28)',
		background: '#ffffff',
	},
	descriptionLabel:{
		wordWrap: 'break-word',
		fontFamily: 'Mukta Mahee, Rubik',
		fontSize: 17,
		fontWeight: 600,
		color: '#0f2365',
	},
	descriptionField:{
		wordWrap: 'break-word',
		// whiteSpace: `nowrap`,
		// overflow: 'hidden',
		// textOverflow: 'ellipsis',
		fontFamily: 'Mukta Mahee, Rubik',
		fontSize: 15,
		fontWeight: 600,
		lineHeight: 1.5,
		minHeight: 30,
		marginTop: 6,
		// '&:hover': {
		// 	overflow: 'visible',
		// 	whiteSpace: `normal`,
		// },
		// '&:focus': {
		// 	overflow: 'visible',
		// 	whiteSpace: `normal`,
		// },
	},
	descriptionControls: {
		display: 'flex',
		justifyContent: 'space-evenly',
		paddingBottom: '20px'
	},
	save:{
		borderRadius: 12.5,
		border: '1px solid #2D3E78',
		padding: '1px 8px',
		cursor: 'pointer',
		fontSize: 14,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		backgroundColor: '#2D3E78',
		color: 'white',
		width:82,
		height: 29,
		marginRight: 10,
	},
	cancel:{
		borderRadius: 12.5,
		border: '1px solid #2D3E78',
		padding: '1px 8px',
		cursor: 'pointer',
		fontSize: 14,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		width:82,
		height: 29,
	},
	send:{
		color:'#aeaeae',
		borderRadius: 12.5,
		border: '1px solid #AEAEAE',
		padding: '1px 8px',
		cursor: 'not-allowed',
		fontSize: 14,
		display: 'flex',
		gap: 3,
		alignItems: 'center',
		justifyContent: 'space-between',
	},
	goTo:{
		border: '1px solid #2D3E78',
		color: '#2D3E78',
		borderRadius: 15,
		padding: '0 5px',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
	},
	geographicFieldValue: {
		fontFamily: 'Mukta Mahee, Rubik',
		fontSize: 15,
		fontWeight: 'bold',
		lineHeight: 1.5,
		display: 'flex',
		alignItems: 'start',
		justifyContent: 'space-between',
		cursor: 'pointer'
	},
	descriptionSeeMore:{
		color: '#6398EE',
		fontSize: 14,
		textDecoration: 'underline',
		cursor: "pointer",
		'&:hover':{
			color: '#4687ef',
		}
	},
	invalidPosition:{
		fontFamily: 'Mukta Mahee, Rubik',
		fontSize: 15,
		fontWeight: 'bold',
		color: '#ca1d10',
		lineHeight: 1.5
	},
	eventClipValue: {
		fontFamily: 'Mukta Mahee, Rubik',
		fontSize: 15,
		fontWeight: 'bold',
		lineHeight: 1.5,
		display: 'flex',
		alignItems: 'start',
	},
	noEventClipValue:{
		fontFamily: 'Mukta Mahee, Rubik',
		fontSize: 15,
		fontWeight: 'bold',
		lineHeight: 1.5
	},
	button:{
		cursor: 'pointer'
	},
	downloadLink:{
		cursor: 'pointer',
		height: '48px'
	},
	downloadClicked:{
		opacity: 0.5
	}
});
