import {FeatureType} from '../../definition/Enums';

export const convertObstaclesToJson = (obstacleArray) => {
    const geoJsonObject = {
        'type': 'FeatureCollection',
        'features': []
    };
    let feature;
    obstacleArray.forEach(obstacle => {
        if (!obstacle.is_position_valid) {
            return;
        }
        feature = {
            'type': 'Feature',
            'properties': {
                category: FeatureType.hazard,
                id: obstacle.id,
                obstacleType: obstacle.obstacle_type,
                obstacleDescription: obstacle.obstacle_description,
                severity: obstacle.severity,
                other: obstacle.other,
                remarks: obstacle.remarks,
                createdBy: obstacle.created_by,
                lastSeenBy: obstacle.last_seen_by,
                operatorId: obstacle.operator_id,
                lastSeenByOperator: obstacle.last_seen_by_operator,
                lastSeenTime: obstacle.last_seen_time,
                creationTime: obstacle.creation_time,
                clipId: obstacle.clip_id
            },
            "geometry": {
                "type": "Point",
                "coordinates": [
                    obstacle.longitude,
                    obstacle.latitude
                ]
            }
        };
        geoJsonObject.features.push(feature);
    });
    return geoJsonObject;
};

export const convertObstacleToJson = (obstacle) => {
    return {
        'type': 'FeatureCollection',
        'features': [{
            'type': 'Feature',
            'properties': {
                'self': true,
                category: FeatureType.hazard,
                'id': obstacle.sourceId,
                'severity': obstacle.obstacleEvent ? obstacle.obstacleEvent.severity : obstacle.severity,
                'obstacleType': obstacle.obstacleEvent ? obstacle.obstacleEvent.obstacleType : obstacle.obstacleType,
                'obstacleDescription': obstacle.obstacleEvent ? obstacle.obstacleEvent.obstacleDescription : obstacle.obstacleDescription
            },
            "geometry": {
                "type": "Point",
                "coordinates": [obstacle.obstacleEvent ? obstacle.obstacleEvent.Longitude : obstacle.Longitude, obstacle.obstacleEvent ? obstacle.obstacleEvent.Latitude : obstacle.Latitude]
            }
        }]
    };
};

export const convertSafetyEventsToJson = (safetyEventArray) => {
    const geoJsonObject = {
        'type': 'FeatureCollection',
        'features': []
    };
    let feature;
    safetyEventArray.forEach(safetyEvent => {
        if (!safetyEvent.is_position_valid) {
            return;
        }
        feature = {
            'type': 'Feature',
            'properties': {
                category: FeatureType.safetyEvent,
                id: safetyEvent.id,
                typeOfEvent: safetyEvent.type_of_event,
                eventDescription: safetyEvent.event_description,
                severity: safetyEvent.severity,
                other: safetyEvent.other,
                remarks: safetyEvent.remarks,
                createdBy: safetyEvent.created_by,
                operatorId: safetyEvent.operator_id,
                creationTime: safetyEvent.creation_time,
                angle: safetyEvent.angle,
                distance: safetyEvent.distance,
                speed: safetyEvent.speed,
                clipId: safetyEvent.clip_id
            },
            "geometry": {
                "type": "Point",
                "coordinates": [
                    safetyEvent.longitude,
                    safetyEvent.latitude
                ]
            }
        };
        geoJsonObject.features.push(feature);
    });
    return geoJsonObject;
};

export const tagObstacles = (geoJson) => {
    geoJson.features.forEach(feature => {
        feature.properties.category = FeatureType.obstacle;
    });
    return geoJson;
}

export const filterObstaclesValidPosition = (geoJson) => {
    const filtered = {...geoJson};
    filtered.features = filtered.features.filter(feature => feature.properties.isPositionValid !== false);
    return filtered;
}
