import { makeStyles } from '@material-ui/core/styles';
import profileConfig from '../../core/profiles/profileConfig';

interface Props {
	miniMapSize: number
}

export default makeStyles({
	root: {
		backgroundColor: '#3a475d',
		width: '100%',
		height: '100%',
	},
	// @ts-ignore
	miniMap: ({ miniMapSize }: Props) => {
		const style = {
			position: 'fixed',
			left: `calc(50% - ${Math.floor(miniMapSize / 2)}px)`,
			bottom: '5%',
		};
		if (profileConfig().vertical) {
			style.bottom = '20%';
		}
		return style;
	},
	logoContainer: {
		position: 'absolute',
		left: 27,
		bottom: 15,
	},
	logo: {
		width: 90,
	},
	clientLogo: {
		position: 'fixed',
		right: 23,
		bottom: 40,
		width: 90,
	},
	version:{
		color: '#99A4B9',
		fontSize: 12,
		textAlign: 'center',
	},
	alerts: () => {
		const style = {
			width: 313,
			padding: 15,
		};
		if (profileConfig().vertical) {
			style['margin'] = 'auto';
		} else {
			style['right'] = 0;
			style['position'] = 'absolute';
		}
		return style;
	},
	statusBar: {
		position: 'absolute',
		right: 0,
		bottom: 0,
	},
	// @ts-ignore
	muteButton: () => {
		const style = {
			position: 'absolute',
			top: 24,
			// '&:focus, &:hover,&:active,&::before, &:active::before, &:focus::before, &.Mui-active, &.Mui-focusVisible': {
			// 	background: '#3a475d',
			// },
		};
		if (profileConfig().vertical) {
			style['right'] = 40;
		} else {
			style['left'] = 40;
		}
		return style;
	},
});
