import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ListItem from '@material-ui/core/ListItem';
import moment from 'moment';
import { getIsMultiSelected, getSelectedFeatureId, getMultiSelected } from '../../../store/selectors/mining/mining.selectors';
import useStyles from './TableListItem.css';
import { selectFeature, multiSelect, multiUnselect } from '../../../store/actions/mining.actions';
import olMapService from '../../../core/services/ol-map.service';
import WhiteCheckbox from '../../../designComponents/WhiteCheckbox';
import { titleCase } from '../../../core/auxilary/strings';
import archivedItem from '../../../assets/images/archivedItem.svg'

interface Iprops {
	date: number;
	id: string;
	symbol: string;
	label: string;
	isActive: boolean;
}

function TableListItem({ date, id, symbol, label, isActive }: Iprops) {
	const [isHover, setIsHover] = useState(false);
	const dispatch = useDispatch();
	const classes = useStyles();
	const selectedFeatureId = useSelector(getSelectedFeatureId);
	const isMultiSelected = useSelector(getIsMultiSelected);
	const multiSelected = useSelector(getMultiSelected);

	const selected = multiSelected.includes(id);
	const timestamp = moment.unix(date);

	const itemSelected = (id: string) => {
		if (id === selectedFeatureId) {
			dispatch(selectFeature(null));
		} else {
			olMapService.selectFeature(id);
			dispatch(selectFeature(id));
		}
	};

	const selectMulti = (e) => {
		e.stopPropagation();
		if(selected){
			dispatch(multiUnselect(id));
		}else{
			dispatch(multiSelect(id));
		}
	};

	const showCheckbox = isMultiSelected || isHover;
	return (
		<ListItem button key={id} className={id === selectedFeatureId ? classes.listItemSelected : classes.listItem}
				  onClick={() => {
					  itemSelected(id);
				  }}>
			<div
				onMouseEnter={() => setIsHover(true)}
				onMouseLeave={() => setIsHover(false)}
				className={classes.symbolSection}
				onClick={selectMulti}
			>
				{
					showCheckbox ?
						<WhiteCheckbox checked={selected} className={classes.checkbox} />
						:
						<img src={symbol} className={classes.symbol} />
				}
			</div>
			<span className={classes.label}>{titleCase(label)}
				{!isActive && <img className={classes.archivedIcon} src={archivedItem}/>}
			</span>
			<span />
			<span className={classes.date}>{timestamp.format('HH:mm')} {timestamp.format('DD/MM/YY')}</span>
		</ListItem>
	);
}

export default TableListItem;
