import React from 'react';
import { useSelector } from 'react-redux';
import MapContainer from '../../features/map/map-container/MapContainer';
import MineceptStatusBar from '../../features/mining-truck/MineceptStatusBar/MineceptStatusBar';
import FeatureCard from '../../features/featureCard/FeatureCard';
import useStyles from './BermPage.css';
import TruckAlerts from '../../features/mining-truck/truckAlerts/truckAlerts';
import {getObstacleAlerts, getWarningSectors} from '../../store/selectors/mining/mining.selectors';
import MiniAlert from '../../features/miniAlert/MiniAlert';
import VerticalReverseAlertBar from '../../features/mining-truck/verticalReverseAlertBar/verticalReverseAlertBar';
import { showMiniMap } from "../../services/obstacles";
import { isShowConnected } from '../../services/connected';
import {isShowAlertsInWheel} from '../../services/obstacles';


const miniMapSize = parseInt(process.env.REACT_APP_MINI_MAP_SIZE as string);

const BermPage = () => {
	const classes = useStyles();
	const obstacles = useSelector(getObstacleAlerts);
	const sectors = useSelector(getWarningSectors);

	const isMinimapSizeSmall=true;
	return (
		<>

			<div className={classes.reverseView}>
				<TruckAlerts />
				<MapContainer />
			</div>
			<div className={classes.alertView} >
				<VerticalReverseAlertBar />
				{ !isShowAlertsInWheel && <div className={classes.alerts}>
					{
						obstacles.slice(0, 2).map((obstacle, key)=><MiniAlert severity={obstacle.severity} type={obstacle.obstacleType} description={obstacle.obstacleDescription} key={key} small={true}/>)
					}
				</div>}
			</div>
			{showMiniMap(sectors, obstacles,  miniMapSize, classes.miniMap,isMinimapSizeSmall)}
			{isShowConnected() && <div className={classes.statusBar}><MineceptStatusBar/></div>}
			<FeatureCard/>
		</>
	);
};

export default BermPage;
