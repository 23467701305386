import olMapService from '../../core/services/ol-map.service';
import { sourceMapOptions } from '../../definition/source-map-options';

export enum MapActionTypes {
	TOGGLE_FOLLOW_ROUTE = '[Map] Toggle follow route',
	SET_FOLLOW_ROUTE = '[Map] Set follow route',
	TOGGLE_STATIC_STATE = '[Map] Toggle static state',
	SET_STATIC_STATE = '[Map] Set static state',
	ADD_MAP_SOURCE = '[Map] add map source',
	REMOVE_MAP_SOURCE = '[Map] remove map source',
	ROTATION_CHANGED = '[Map] rotation changed',
	CLEAR_ROTATION = '[Map] clear rotation',
	TOOGLE_AUDIO_STATE = '[Map] Toggle audio state'
}

export const toggleAudioState = () => (dispatch) => {
	dispatch({
		type: MapActionTypes.TOOGLE_AUDIO_STATE
	});
};

export const toggleFollowRoute = () => (dispatch) => {
	dispatch({
		type: MapActionTypes.TOGGLE_FOLLOW_ROUTE
	});
};

export const setFollowRoute = (followRouteState: boolean) => (dispatch) => {
	dispatch({
		type: MapActionTypes.SET_FOLLOW_ROUTE,
		payload: followRouteState
	});
};

export const toggleMapStaticState = () => (dispatch) => {
	dispatch({
		type: MapActionTypes.TOGGLE_STATIC_STATE
	});
};

export const setMapToStaticState =() => {
	return{
		type: MapActionTypes.SET_STATIC_STATE,
		payload: true
	}
};

export const setMapStaticState = (staticState: boolean) => (dispatch) => {
	dispatch({
		type: MapActionTypes.SET_STATIC_STATE,
		payload: staticState
	});
};

export const addMapLayer = (layerKey: sourceMapOptions) => (dispatch) => {
	olMapService.showMapLayer(layerKey);
	dispatch({
		type: MapActionTypes.ADD_MAP_SOURCE,
		payload: layerKey
	});
};

export const removeMapLayer = (layerKey: sourceMapOptions) => (dispatch) => {
	olMapService.hideLayer(layerKey);
	dispatch({
		type: MapActionTypes.REMOVE_MAP_SOURCE,
		payload: layerKey
	});
};

export const rotationChanged = (rotationValue: number) => ({
	type: MapActionTypes.ROTATION_CHANGED,
	payload: rotationValue
});

export const clearRotation = () => (dispatch) => {
	olMapService.clearRotation();
	dispatch({
		type: MapActionTypes.CLEAR_ROTATION
	});
};
