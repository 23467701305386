import React, {useContext, useEffect, useRef} from 'react';
import {useSelector} from 'react-redux';
import {getVolume} from '../../../store/selectors/core.selector';
import {
    getAlertSwitches, getAngleAlert, getBermAlert,
    getIsFalling,
    getIsGoingReverse,
    getSpeedAlert,
} from '../../../store/selectors/mining/mining.selectors';
import {getAudioState} from '../../../store/selectors/map.selectors';
import {getAdasZones, getLaneAlert} from '../selectors/adas-alerts.selectors';
import {getAlertStringFromObstacles} from './obstacleAlerts';
import {getAlertStringFromUnloading} from "./unloadingAlerts";
import {config} from '../../../services/config';
import {Falling} from '../../../definition/Enums';
import videoContext from '../../../store/context/videoContext';


const initAudioPlayers = (audioPlayers) => {
    const soundList = config.soundList;
    Object.keys(soundList).forEach((audioType) => {
        if (audioPlayers[audioType]) return;
        audioPlayers[audioType] = new Audio(soundList[audioType].src);
        audioPlayers[audioType].loop = soundList[audioType].loop;
        audioPlayers[audioType].playbackRate = soundList[audioType].speed;
        audioPlayers[audioType].pause();
    });
};

const setVolume = (volume: number) => {
    Object.keys(audioPlayers).forEach((player) => {
        audioPlayers[player].volume = volume;
    });
};

const mapPropsToAlerts = (props): string => {
    if (props.fallingWarning === Falling.Alert) return FALLING;
    // @ts-ignore
    const unloadingAlert: [string, number] = getAlertStringFromUnloading({
        reverse: props.isGoingReverse,
        speed: props.speedAlert,
        angle: props.angleAlert,
        berm: props.bermAlert,
        zone: props.zoneAlert,
        lane: props.laneAlert
    })
    const obstacleAlert = getAlertStringFromObstacles(props.obstacles);
    if (obstacleAlert[1] > unloadingAlert[1]) {
        return obstacleAlert[0];
    }
    if (unloadingAlert[1] > 0) {
        return unloadingAlert[0];
    }
    return 'none';
};

const audioPlayers = {};
const NONE = 'none';
const FALLING = 'falling';

function AlertsSound2({obstacles}) {
    const volume = useSelector(getVolume);
    const adasAlerts = useSelector(getAdasZones);
    const audioStateOn = useSelector(getAudioState);
    const bermAlert = useSelector(getBermAlert);
    const angleAlert = useSelector(getAngleAlert);
    const speedAlert = useSelector(getSpeedAlert);
    const isGoingReverse = useSelector(getIsGoingReverse);
    const fallingWarning = useSelector(getIsFalling);
    const laneAlert = useSelector(getLaneAlert);
    const alertSwitches = useSelector(getAlertSwitches);
    const currentSound = useRef(NONE);
    const nextSound = useRef({
        audio: NONE,
        playDate: 0,
        duration: 0
    });
    const nextSoundTimeoutHandler = useRef<NodeJS.Timeout | undefined>(undefined);
    const videoCtx = useContext(videoContext);
    useEffect(() => {
        initAudioPlayers(audioPlayers);
    }, []);

    useEffect(() => {
        setVolume(volume);
    }, [volume]);

    const audioToPlay = mapPropsToAlerts({
        zoneAlert: adasAlerts.size > 0,
        audioStateOn,
        bermAlert: alertSwitches.midAxleHeightAlert ? bermAlert : 0,
        angleAlert: alertSwitches.approachAngleAlert ? angleAlert : 0,
        speedAlert: alertSwitches.speedAlert ? speedAlert : 0,
        isGoingReverse,
        fallingWarning,
        laneAlert,
        obstacles: (videoCtx && !videoCtx.isPlayed) ? [] : obstacles,
    });

    const isChanged = audioToPlay !== nextSound.current.audio || audioToPlay === NONE;

    if (isChanged) {
        if (nextSoundTimeoutHandler.current) {
            clearTimeout(nextSoundTimeoutHandler.current);
            nextSoundTimeoutHandler.current = undefined;
        }
        const currentPlayer = audioPlayers[currentSound.current];
        const isImmediate = currentSound.current === NONE ||
            (!isChanged && currentSound.current === FALLING) ||
            (currentSound.current !== NONE && audioToPlay === NONE) ||
            (currentPlayer && currentPlayer.paused) ||
            (videoCtx && !videoCtx.isPlayed);
        if (isImmediate) {
            nextSound.current = {
                audio: NONE,
                duration: 0,
                playDate: 0
            };
            if (currentPlayer && !currentPlayer.paused) {
                currentPlayer.pause();
                currentPlayer.currentTime = 0;
            }
            const player = audioPlayers[audioToPlay];
            if (player) {
                if (!player.loop || player.paused) {
                    player.currentTime = 0;
                    player.play();
                }
            }
            currentSound.current = audioToPlay;
        } else {
            let diff = 0;
            if (currentPlayer) {
                let currentTime = currentPlayer.currentTime
                if (videoCtx && videoCtx.isPlayed && videoCtx.currentTime && videoCtx.lastPlayedEventTime) {
                    currentTime = (videoCtx.currentTime - videoCtx.lastPlayedEventTime) % currentPlayer.duration;
                }
                diff = (currentPlayer.duration - currentTime) * 1000;
            }
            const playDate = Date.now() + diff;
            const nextPlayer = audioPlayers[audioToPlay];
            nextSound.current = {
                audio: audioToPlay,
                duration: nextPlayer ? nextPlayer.duration : 0,
                playDate
            }
            nextSoundTimeoutHandler.current = setTimeout(() => {
                if (currentSound.current === nextSound.current.audio) {
                    const player = audioPlayers[currentSound.current];
                    if (player) {
                        if (!player.loop) {
                            player.currentTime = 0;
                            player.play();
                        }
                    }
                } else {
                    const currentPlayer = audioPlayers[currentSound.current];
                    if (currentPlayer) {
                        currentPlayer.pause();
                        currentPlayer.currentTime = 0;
                    }
                    const nextPlayer = audioPlayers[nextSound.current.audio];
                    if (nextPlayer) {
                        if (!nextPlayer.loop || nextPlayer.paused) {
                            nextPlayer.currentTime = 0;
                            nextPlayer.play();
                        }
                    }
                }
                currentSound.current = nextSound.current.audio;
                nextSound.current = {
                    audio: NONE,
                    duration: 0,
                    playDate: 0
                };
            }, diff);
        }
    }

    return (
        <></>
    );
}

export default AlertsSound2;
