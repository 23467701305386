import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import MapControl from '../../features/map-controls/map-control/MapControl';
import ErrorMessages from '../../features/error-messages/error-growl/ErrorGrowl';
import Menu from '../../features/menu/menu/Menu';
import MapContainer from '../../features/map/map-container/MapContainer';
import profileConfig from '../../core/profiles/profileConfig';
import StatusBar from '../../features/sensors/status-indicator/status-bar/StatusBar';
import AdasAlertsDisplay from '../../features/adas-alerts/adas-alerts-display/AdasAlertsDsiplay';
import ReverseAlertBar from '../../features/mining-truck/reverseAlertBar/ReverseAlertBar';
import MineceptStatusBar from '../../features/mining-truck/MineceptStatusBar/MineceptStatusBar';
import FeatureCard from '../../features/featureCard/FeatureCard';
import {getIsGoingReverse, getObstacleAlerts, getWarningSectors} from '../../store/selectors/mining/mining.selectors';
import useStyles from './FullMapPage.css';
import { useSnackbar } from 'notistack';
import globals from '../../core/globals';
import Emitter from '../../emitter';
import { selectFeature } from '../../store/actions/mining.actions';
import TruckAlerts from '../../features/mining-truck/truckAlerts/truckAlerts';
import { showMiniMap }  from "../../services/obstacles";
import VideoPlayer from '../../features/videoPlayer/videoPlayer';
import { getUserInfo } from '../../store/selectors/core.selector';
import { isShowConnected } from '../../services/connected';
import {isShowAlertsInWheel} from '../../services/obstacles';

const miniMapSize = parseInt(process.env.REACT_APP_MINI_MAP_SIZE as string);

const FullMapPage = () => {
	const dispatch = useDispatch();
	const isGoingReverse = useSelector(getIsGoingReverse);
	const showReverseAlertBar = profileConfig().showReverseAlertBar && isGoingReverse;
	const isShowMiniMap = isGoingReverse ? profileConfig().showMiniMapOnReverseScreen : profileConfig().showMiniMapOnForwardScreen;
	const { enqueueSnackbar } = useSnackbar();
	const leftStatusBar = (profileConfig().showMiniMapOnForwardScreen || profileConfig().showMiniMapOnReverseScreen);
	const isShowConnectedBar = profileConfig().showIsConnectedBar;
	const classes = useStyles({leftStatusBar, isShowConnectedBar});

	const obstacles = useSelector(getObstacleAlerts);
	const sectors = useSelector(getWarningSectors);
	const userInfo = useSelector(getUserInfo);



	const isMinimapSizeSmall=true;

	useEffect(()=>{
		globals.actions.setSnack = enqueueSnackbar;
	});

	useEffect(()=>{
		const listener = ()=> {
			dispatch(selectFeature());
		};
		Emitter.on('mapClicked', listener);
		return () => {
			Emitter.off('mapClicked', listener);
		};
	});

	return (
		<>
			{!isGoingReverse && <MapControl />}
			<ErrorMessages />
			{!isGoingReverse && <Menu />}
			<MapContainer />
			{profileConfig().showStatusBar && <StatusBar />}
			{!isShowAlertsInWheel && <AdasAlertsDisplay/>}
			{showReverseAlertBar && <TruckAlerts /> }
			{showReverseAlertBar && <ReverseAlertBar /> }
			{isShowMiniMap && showMiniMap(sectors, obstacles, miniMapSize, classes.miniMap,isMinimapSizeSmall)}
			{isShowConnected() && isShowConnectedBar && <div className={classes.statusBar}><MineceptStatusBar/></div>}
			{/* {userInfo.user && <div className={classes.logoutBar}><UserLogout /></div>} */}
			<FeatureCard/>
			<VideoPlayer />
		</>
	);
};

export default FullMapPage;
