import { getMineceptObstacles$, getMineceptStatus$ } from '../../store/selectors/mining/mining.selectors';
import { store } from '../../index';
import { setForwardView, setIsFalling, setObstacleAlerts, setWarningSectors } from '../../store/actions/mining.actions';
import mineceptVectorLayerProvider from './layers/mineceptVectorLayerProvider';
import { MineceptLayers } from '../../definition/mineceptLayers';
import { Falling } from '../../definition/Enums';
import { config } from '../../services/config';
import { distinct } from 'rxjs/operators';

const clearObstaclesDelay = 1000 * (parseInt(process.env.REACT_APP_CLEAR_OBSTACLES_DELAY as string, 10) || 1);
const clearCliffWarningDelay = 1000 * (parseInt(process.env.REACT_APP_CLEAR_CLIFF_WARNING as string, 10) || 1);
const forwardViewDelay = 1000 * (parseInt(process.env.REACT_APP_RETURN_TO_FORWARD_VIEW as string, 10) || 1);

export const initSideEffects = () =>{
	let mineceptStatus = 0;

	const clearObstacles = () => {
		store.dispatch(setObstacleAlerts([]) as any);
		store.dispatch(setWarningSectors([0,0,0,0,0,0,0,0,0,0,0,0]) as any);
		mineceptVectorLayerProvider.clearLayerData(MineceptLayers.obstacles);
	}

	const clearObstaclesIfDisconnected = () =>{
		if(mineceptStatus  < 2 ) return;
		clearObstacles();
	}

	const clearCliffWarningIfDisconnected = () =>{
		if(mineceptStatus  < 2 ) return;
		store.dispatch(setIsFalling(Falling.Ready) as any);
	}

	const forwardViewIfDisconnected = () =>{
		if(mineceptStatus  < 2 ) return;
		store.dispatch(setForwardView() as any);
	}

	getMineceptStatus$().subscribe(value => {
		mineceptStatus = value ? value.status : 0;
		if(mineceptStatus > 1) {
			setTimeout(clearObstaclesIfDisconnected, clearObstaclesDelay);
			setTimeout(clearCliffWarningIfDisconnected, clearCliffWarningDelay);
			setTimeout(forwardViewIfDisconnected, forwardViewDelay);
		}
	})

	let clearObstaclesHandler: NodeJS.Timeout | undefined = undefined;
	getMineceptObstacles$().pipe(distinct(x => JSON.stringify(x))).subscribe((obstacles) => {
		if (clearObstaclesHandler) {
			clearTimeout(clearObstaclesHandler);
			clearObstaclesHandler = undefined;
		}
		if (!obstacles || obstacles.length === 0) {
			return;
		}
		setTimeout(clearObstacles, config.general.obstacles.clearTimeoutMS);
	})
};
