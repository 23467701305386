import { makeStyles } from '@material-ui/core/styles';

// const viewportHeight = window.innerHeight;
interface Props{
	display:boolean
	archive:boolean
}
export default makeStyles({
	root: (props:Props) => ({
		transform:  `translate(${props.display? 94 : -500 }px,0px)`,
		position: 'absolute',
		width: 300,
		height: '100vh',
		// opacity: 0.92,
		// background: '#212e43',
		background: 'rgba(33,46,67,0.92)',
		transition: '0.5s',
	}),
	titleBar:{
		display: 'flex',
		justifyContent: 'space-between',
		padding: '10px 20px',
		width: '100%',
		height: 48,
		opacity: 0.92,
		background: '#485669',
	},
	closeButton:{
		cursor: 'pointer',
	},
	button:{
		cursor: 'pointer',
		width:30,
		height: 30,
	},
	closeSymbol:{
		width: 15,
		height: 15,
	},
	filterBar:{
		display: 'flex',
		justifyContent: 'space-between',
		height: 48,
		padding: '0 0 0 20px',
		alignItems: 'center'
	},
	filterBarButtons:{
		display: 'flex',
		justifyContent: 'flex-end',
	},
	archive: props => {
		const style = {
			display: 'flex',
			gap: 3,
			//border: '1px solid white',
			padding: '3px 10px',
			height:32,
		//	borderRadius: 24,
			fontSize:14,
			color: 'white',
			fontFamily: 'Mukta Mahee',
			// cursor: 'pointer',
			// '&:hover': {
			// 	backgroundColor: '#171E29'
			// },
		};
		// if(props.archive){
		// 	// @ts-ignore
		// 	style.background = 'rgba(255,255,255,0.3)'
		// }
		return style;
	},
	list:()=>{
		const viewportHeight = window.innerHeight;
		return {
			maxHeight: viewportHeight -48 -48,
				overflow: 'auto',
		}
	},
	listItem:{
		padding: '12px 20px',
		display: 'grid',
		gridTemplateColumns: '40px auto'
	},
	listItemSelected:{
		padding: '12px 20px',
		display: 'grid',
		gridTemplateColumns: '40px auto',
		background: '#131E30',
	},
	label:{
		fontSize: 15,
		fontWeight: 'bold'
	},
	date:{
		fontSize: 15,
	},
	symbol:{
		width: 25,
		height: 25,
	},
	sortButton:{
		position: 'relative',
		cursor: 'pointer',
		padding: '5px 5px 0 5px',
		'&:hover': {
			backgroundColor: '#171E29',
			borderRadius: 25
		},
	},
	buttonSection:{
		position: 'relative',
		cursor: 'pointer',
		padding: '5px 5px 0 5px',
	},
	buttonStyle:{
		'&:hover': {
			backgroundColor: '#171E29',
			borderRadius: 25
		},
	},
	moreButton:{
		paddingTop: 5,
		fontSize: 25,
	}
});
