import React from 'react';
import { Line } from 'react-chartjs-2';
import { formatDateRange } from '../../../../core/auxilary/formatting';
import { config } from '../../../../services/config';
import { IMineceptDashboardSiteEventsByType } from '../../../../store/reducers/mining/IMiningReducerState';
import { reverse } from '../../utils';

interface IProps {
	graph: IMineceptDashboardSiteEventsByType;
	period: number;
}

const Chart = ({ graph, period }: IProps) => (
	<Line
		options={{
			plugins: {
				title: {
					display: true,
					text: 'Safety events by type over time',
				},
				datalabels: {
					display:
						config.general.dashboard.showDataLabels
							.siteEventsByType,
					color: 'black',
				},
			},
			maintainAspectRatio: false,
			scales: {
				y: { beginAtZero: true },
			},
		}}
		data={{
			labels: reverse(graph.buckets).map(range =>
				formatDateRange(range, period === 1)
			),
			datasets: Object.entries(graph.data)
				.sort((a, b) => a[0].localeCompare(b[0]))
				.map(([type, dataset]) => ({
					type: parseInt(type),
					dataset,
				}))
				.map(({ type, dataset }) => ({
					label: config.hazardAndSafetyLabels.safeties[type],
					data: reverse(dataset),
					tension: 0.2,
					borderColor:
						config.hazardAndSafetyColors.safeties[type] ||
						config.general.dashboard.defaultColor,
					borderWidth: 1,
				})),
		}}
	/>
);

export default Chart;
