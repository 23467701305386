import React, {useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import useStyles from './filterBar.css';
import closeImage from '../../../../assets/images/clear.svg';
import {
    FormControl,
    MenuItem,
    Select,
    ThemeProvider,
    createMuiTheme,
} from '@material-ui/core';
import Input from '@material-ui/core/Input';
import {MuiPickersUtilsProvider, DateTimePicker} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import {isSeverityZeroWarn, severityLabels} from '../../../../definition/severity';
import WhiteCheckbox from '../../../../designComponents/WhiteCheckbox';
import ArchiveIcon from '../../../../assets/images/toArchiveIcon.svg';

interface Iprops {
    display: boolean,
    title: string,
    close: () => void,
    setFilters: any,
    clearFilters: () => any,
    filterSelector: any,
    controlsSelector: any,
    existingTypesSelector: any,
    typeLabels: Array<string>,
    vehiclesSelector: any
}

const MenuProps = {
    anchorOrigin: {
        vertical: "bottom",
        horizontal: "left"
    },
    transformOrigin: {
        vertical: "top",
        horizontal: "left"
    },
    getContentAnchorEl: null,
    PaperProps: {
        style: {
            maxHeight: 230,
            width: 200,
            color: 'white',
        },
    },
};

const calendarMaterialTheme = createMuiTheme({
    palette: {
        primary: {
            contrastText: "#0f2365",
            dark: "#FFFFFF",
            main: "#FFFFFF",
            light: "#FFFFFF"
        }
    }
});

let effectiveSeverityLabels = severityLabels.map((label, key) => ({label, key}));
if (!isSeverityZeroWarn) {
    effectiveSeverityLabels = effectiveSeverityLabels.slice(1)
}

function FilterBar({
                       display,
                       controlsSelector,
                       close,
                       title,
                       filterSelector,
                       vehiclesSelector,
                       existingTypesSelector,
                       setFilters,
                       typeLabels,
                       clearFilters
                   }: Iprops) {
    const dispatch = useDispatch();
    const filters = useSelector(filterSelector);
    const existingTypesToFilter = useSelector(existingTypesSelector);
    const [severity, setSeverity] = useState<number[]>(filters.severity);
    const [type, setType] = useState<number[]>(filters.type);
    const [filterStart, setFilterStart] = useState(filters.filterStart);
    const [filterEnd, setFilterEnd] = useState(filters.filterEnd);
    const [startDate, setStartDate] = useState<Date | null>(filters.startTime);
    const [endDate, setEndDate] = useState<Date | null>(filters.endTime);
    const [isArchive, setIsArchive] = useState(filters.archive);
    const [isFilterVehicles, setIsFilterVehicles] = useState(filters.filterVehicles);
    const [selectedVehicles, setSelectedVehicles] = useState(filters.vehicles);

    const controls = useSelector(controlsSelector);
    const archive = controls.archive;
    const classes = useStyles({display, archive});
    const filterKind = title.split(' ')
    const toggleArchive = () => {
        setIsArchive((prev: boolean) => !prev);
    }
    const vehicles = useSelector(vehiclesSelector);

    const handleChangeSeverity = (event: React.ChangeEvent<{ value: unknown }>) => {
        setSeverity(event.target.value as number[]);
    };

    const handleChangeType = (event: React.ChangeEvent<{ value: unknown }>) => {
        setType(event.target.value as number[]);
    };

    const handleStartChange = (date: Date | null) => {
        if (!date) return;
        // date.setHours(0);
        // date.setMinutes(0);
        setStartDate(date);
    };

    const handleEndChange = (date: Date | null) => {
        if (!date) return;
        // date.setHours(23);
        // date.setMinutes(59);
        setEndDate(date);
    };
    const toggleFilterStart = () => setFilterStart(!filterStart);
    const toggleFilterEnd = () => setFilterEnd(!filterEnd);

    const handleClearFilters = () => {
        dispatch(clearFilters());
        close();
    }

    const handleToggleIsFilterVehicles = () => setIsFilterVehicles(!isFilterVehicles);
    const handleVechicleSelectionChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        setSelectedVehicles(event.target.value as string[]);
    }

    const applyFilters = () => {
        const tmpFilter = {
            severity,
            type,
            filterStart: filterStart,
            startTime: startDate,
            filterEnd: filterEnd,
            endTime: endDate,
            archive: isArchive,
            filterVehicles: isFilterVehicles,
            vehicles: selectedVehicles
        };
        dispatch(setFilters(tmpFilter));

        close();
    };
    return (
        <div className={classes.root}>
            <div className={classes.titleBar}>
                <span>{title}</span>
                <img className={classes.closeButton} src={closeImage} onClick={close}/>
            </div>
            <div className={classes.filterSection}>
                <ThemeProvider theme={calendarMaterialTheme}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <div className={classes.sectionTitle}>By Date:</div>
                        <div className={`${classes.inputRow} date-time-picker-filter`}>
                            <WhiteCheckbox checked={filterStart} onClick={toggleFilterStart}/>
                            <span className={classes.label}>From</span>
                            <DateTimePicker
                                // element style overwritten form overrides.css
                                disableToolbar
                                variant="inline"
                                format="MM/dd/yyyy   HH:mm"
                                ampm={false}
                                margin="dense"
                                value={startDate}
                                onChange={handleStartChange}
                                InputProps={{classes: {underline: `${classes.underline} ${classes.underlineDate}`}}}
                            />

                        </div>
                        <div className={`${classes.inputRow} date-time-picker-filter`}>
                            <WhiteCheckbox checked={filterEnd} onClick={toggleFilterEnd}/>
                            <span className={classes.label}>Until</span>
                            <DateTimePicker
                                // element style overwritten form overrides.css
                                disableToolbar
                                variant="inline"
                                format="MM/dd/yyyy   HH:mm"
                                ampm={false}
                                margin="dense"
                                value={endDate}
                                onChange={handleEndChange}
                                InputProps={{classes: {underline: `${classes.underline} ${classes.underlineDate}`}}}
                            />
                        </div>
                    </MuiPickersUtilsProvider>
                </ThemeProvider>
            </div>
            <div className={classes.separatorLine}/>
            <div className={classes.filterSection}>
                <div className={classes.inputRow}>
                    <div className={classes.sectionTitle}>By Type:</div>
                    <FormControl
                        className={`${classes.formControl} ${classes.formControlType} filterbar-form-control-style-override`}>
                        <Select
                            className={classes.select}
                            multiple
                            value={type}
                            onChange={handleChangeType}
                            input={<Input classes={{
                                underline: classes.underline,
                            }}/>}
                            renderValue={(selected) => ((selected as number[]).map(s => typeLabels[s]) as string[]).join(', ')}
                            // @ts-ignore
                            MenuProps={MenuProps}
                        >
                            {typeLabels.map((label, key) => {
                                return !existingTypesToFilter[key] ? [] :
                                    <MenuItem key={key} value={key}>
                                        <WhiteCheckbox checked={type.indexOf(key) > -1}/>
                                        <span className={classes.selectText}>{label}</span>
                                    </MenuItem>
                            })}
                        </Select>
                    </FormControl>
                </div>

                <div className={classes.inputRow}>
                    <div className={classes.sectionTitle}>By Severity:</div>
                    <FormControl className={`${classes.formControl} filterbar-form-control-style-override`}>
                        {/* element style overwritten form overrides.css */}
                        <Select
                            input={<Input classes={{
                                underline: classes.underline,
                            }}/>}
                            className={classes.select}
                            multiple
                            value={severity}
                            onChange={handleChangeSeverity}
                            renderValue={(selected) => ((selected as number[]).map(s => {
                                const severityLabel = effectiveSeverityLabels.find(x => x.key === s);
                                return severityLabel ? severityLabel.label : 'unknown';
                            }) as string[]).join(', ')}
                            // @ts-ignore
                            MenuProps={MenuProps}
                            // inputProps={{ root:{color:'white'} }}
                        >
                            {effectiveSeverityLabels.map(({label, key}) => (
                                <MenuItem key={key} value={key}>
                                    <WhiteCheckbox checked={severity.indexOf(key) > -1}/>
                                    <span className={classes.selectText}>{label}</span>
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </div>

                <div className={classes.inputRow}>
                    <WhiteCheckbox checked={isFilterVehicles} onClick={handleToggleIsFilterVehicles}/>
                    <div className={classes.label}>By Vehicles:</div>
                    <FormControl
                        className={`${classes.formControl} ${classes.formControlVehicle} filterbar-form-control-style-override`}>
                        <Select className={classes.select}
                                multiple
                                input={<Input classes={{
                                    underline: classes.underline,
                                }}/>}
                                disabled={!isFilterVehicles}
                                value={selectedVehicles}
                                onChange={handleVechicleSelectionChange}
                                renderValue={(selected) => (selected as string[]).join(', ')}>
                            {vehicles.map((vehicle) => (
                                <MenuItem key={vehicle.id} value={vehicle.id}>
                                    <WhiteCheckbox checked={selectedVehicles.includes(vehicle.id)}/>
                                    <span className={classes.selectText}>{vehicle.id} ({vehicle.count})</span>
                                </MenuItem>
                            ))}

                        </Select>
                    </FormControl>
                </div>
            </div>
            <div className={classes.archive} onClick={toggleArchive}>
                <WhiteCheckbox checked={isArchive}/>
                <span className={classes.label}>{`Show Archived ${filterKind[1]}`}</span>
                <img className={classes.archiveIcon} src={ArchiveIcon}/>

            </div>

            <div className={classes.controls}>

                <div onClick={handleClearFilters} className={classes.cancel}>Clear All</div>
                <div onClick={applyFilters} className={classes.apply}>Apply</div>
            </div>
        </div>
    );
}

export default FilterBar;
