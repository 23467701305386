import { minMaxInt } from '../core/auxilary/formatting';

export const isSeverityZeroWarn = process.env.REACT_APP_OBSTACLE_IS_SEVERITY_ZERO_WARN &&
	process.env.REACT_APP_OBSTACLE_IS_SEVERITY_ZERO_WARN.toLowerCase().trim() === 'true';

export const severityLabels = [
	isSeverityZeroWarn ? 'Warning - 1' : 'Safe - 0',
	`Minor - ${isSeverityZeroWarn ? 2 : 1}`,
	`Major - ${isSeverityZeroWarn ? 3 : 2}`,
	`Critical - ${isSeverityZeroWarn ? 4 : 3}`
];

export const noAlertColor = '#FFFFFF';
export const noActiveColor = '#575656';

const severityColors = [
	{icon: isSeverityZeroWarn ? '#FFDC7E' : noAlertColor, text:'#575656'},
	{icon:'#FBAE26', text:'#FFFFFF'},
	{icon:'#FF7100', text:'#FFFFFF'},
	{icon:'#D5001A', text:'#FFFFFF'},
];

export const getSeverityLabel = (severity)=> severityLabels[minMaxInt(severity,0,3)];
export const getSeverityColors = (severity)=> severityColors[minMaxInt(severity,0,3)];
export const getEffectiveSeverityKeys = () => {
	let keys = [0, 1, 2, 3];
	if (!isSeverityZeroWarn) {
		keys = keys.slice(1);
	}
	return keys;
}
