import React from 'react';
import useStyles from './alertInWheel.css';
import { getHazardLabel } from '../../core/services/minecept/auxilary';
import { limitStringLength } from '../../core/auxilary/strings';
import {getHazardIcon} from "../../core/services/icons/obstacleIcons";

interface IProps{
	size:number
	severity: number
	type: number
	description?: string
	small?:boolean
	vertical?:boolean
}

function AlertInWheel({size,severity, type, description, small=false, vertical=false}: IProps) {
	const classes = useStyles({size,severity, small,vertical});
	const symbol = getHazardIcon(type, severity);
	const label = getHazardLabel(type, description);

	return (
		<div className={classes.root}>
			<img className={classes.symbol} src={ symbol }/>
			<span className={classes.label}>{limitStringLength(label, small? 17 : 20)}</span>
		</div>
	);
}

export default AlertInWheel;
