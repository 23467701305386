import io from 'socket.io-client';
import globals from '../../globals';
import mineceptVectorLayerProvider from '../layers/mineceptVectorLayerProvider';
import { store } from '../../../index';
import {
	addHazards,
	addSafetyEvent, clearHazards, clearSafetyEvents,
	removeHazards,
	removeSafetyEvents, restoreHazards, restoreSafetyEvents,
	setHazards,
	setMineceptStatus,
	setSafetyEvents,
	updateHazard,
	updateSafetyEvent,
} from '../../../store/actions/mining.actions';
import profileConfig from '../../profiles/profileConfig';
import { filterObstacles } from './obstacles';
import { MineceptLayers } from '../../../definition/mineceptLayers';
import { phraseSafetyEventsToAlerts } from './auxilary';
import { FeatureType } from '../../../definition/Enums';
import { keycloakService } from '../../../services/keycloak';
import { config } from '../../../services/config';
import { getSelectedSite } from '../../../store/selectors/mining/mining.selectors';

const vehicleId = parseInt(process.env.REACT_APP_VEHICLE_ID as string);

const hazardMinSeverity = process.env.REACT_APP_OBSTACLE_MINIMUM_SEVERITY_TO_SHOW as string;
const hazardTypes = process.env.REACT_APP_OBSTACLE_TYPE_TO_SHOW as string;
const hazardMaxAge = process.env.REACT_APP_OBSTACLE_MAXIMUM_AGE_TO_SHOW_MINUTES as string;

class MineceptServerSocket {
	serverUrl: string = process.env.REACT_APP_WS_SERVER as string;
	socket;

	constructor() {

	}

	getQuery = () => {
		const query: any = {
			hazards: !!profileConfig().showHazards,
			safety_events: !!profileConfig().showSafetyEvents,
		};
		if (profileConfig().filterHazardsFromServer) {
			query.hazard_min_severity = hazardMinSeverity || 0;
			query.hazard_min_severity = hazardMinSeverity || 0;
			query.hazard_max_age = hazardMaxAge || 24 * 60;
			query.hazard_types = hazardTypes || [];
		}
		if (!config.isRequiredLogin && config.isMultiSite && config.siteKey) {
			query.site_key = config.siteKey
		} else if (config.isMultiSite) {
			query.site_id = getSelectedSite(store.getState());
		}
		return query;
	}

	async initSocketListeners(): Promise<void> {
		const query = this.getQuery();
		if (this.socket) {
			this.socket.emit('setSite', query);
			return;
		}
		this.socket = io(`${this.serverUrl}`, {
			query,
		});
		
		this.socket.on('initialObstacles', data => {
			if (data.hazards && data.hazards.length) {
				const filteredObstacles = !profileConfig().filterHazardsFromServer ? data.hazards : filterObstacles(data.hazards, false);
				store.dispatch(setHazards(filteredObstacles) as any);
			}

			if (data.safetyEvents && data.safetyEvents.length) {
				store.dispatch(setSafetyEvents(data.safetyEvents) as any);
			}
		});

		this.postLoginSocketInitialization();
	}


	postLoginSocketInitialization(): void{
		if (profileConfig().showHazards) {
			this.initHazardListeners();
		}

		if (profileConfig().showSafetyEvents) {
			this.initSafetyEventListeners();
		}

		if (profileConfig().showAllSiteVehicles) {
			this.initVehiclePositionListeners();
		}

		this.initSocketConnectListeners();
	}
	initHazardListeners(): void{
		this.socket.on('obstacles', data => {
			const filteredObstacles = filterObstacles(data, false);
			store.dispatch(addHazards(filteredObstacles) as any);
			mineceptVectorLayerProvider.removeFeaturesFromLayerByParam(MineceptLayers.hazards, 'self', true);
		});

		this.socket.on('removeObstacles', obstacles => {
			store.dispatch(removeHazards([...obstacles]) as any);
		});

		this.socket.on('restoreObstacles', obstacles => {
			store.dispatch(restoreHazards([...obstacles]) as any);
		});

		this.socket.on('updateObstacle', hazard => {
			store.dispatch(updateHazard([hazard]) as any);
		});

		this.socket.on('updateObstacles', hazards => {
			store.dispatch(updateHazard(hazards) as any);
		});

		this.socket.on('clearObstacles', () => {
			store.dispatch(clearHazards() as any);
		});
	}

	initSafetyEventListeners(): void{
		this.socket.on('safetyEvents', data => {
			store.dispatch(addSafetyEvent(data) as any);
			// const geoJson = convertSafetyEventsToJson(data);
			// mineceptVectorLayerProvider.addFeaturesToLayer(MineceptLayers.safetyEvents, geoJson);
			if (profileConfig().alertOnIncomingSafetyEvents) {
				phraseSafetyEventsToAlerts(data).map(safetyEvent => globals.actions.setSnack(safetyEvent));
			}
		});

		this.socket.on('removeSafetyEvents', eventIds => {
			store.dispatch(removeSafetyEvents([...eventIds]) as any);
		});


		this.socket.on('restoreSafetyEvents', safetyEvents => {
			store.dispatch(restoreSafetyEvents([...safetyEvents]) as any);
		});

		this.socket.on('updateSafetyEvent', safetyEvent => {
			store.dispatch(updateSafetyEvent(safetyEvent) as any);
		});

		this.socket.on('clearSafetyEvents', () => {
			store.dispatch(clearSafetyEvents() as any);
		});
	}

	initVehiclePositionListeners():void {
		this.socket.on('vehiclePositionUpdate', ({ id, isPositionValid, longitude, latitude, rotation, siteId }) => {
			const selectedSite = store.getState().mining.multiSite.selectedSite;
			if (siteId !== undefined && selectedSite !== siteId) {
				return; // filter out vechicle update before site change
			}
			if (id === vehicleId) return;
			const properties = {
				rotation,
				vehicleId: id,
				lastSeen: Date.now(),
				category: FeatureType.vehicle,
				inActive:false
			};
			mineceptVectorLayerProvider.updateFeaturePosition(MineceptLayers.vehicles, `vehiclePosition_${id}`, 
				isPositionValid, [longitude, latitude], properties);
		});
		setInterval(()=>{mineceptVectorLayerProvider.updateInactiveProperty(MineceptLayers.vehicles)},5000);
	}

	initSocketConnectListeners(): void {
		if (profileConfig().listenToMineceptSensor) return;

		this.socket.on('connect', async () => {
			this.socket.sendBuffer = []
			store.dispatch(setMineceptStatus({ status: 0, label: '', text: '' }) as any);

			// Request query with token
			const token = await keycloakService.authToken();
			if (token) {
				this.socket.emit('query', { ...this.getQuery(), token });
			}
		});

		this.socket.on('disconnect', () => {
			store.dispatch(setMineceptStatus({ status: 3, label: '', text: '' }) as any);
		});

		this.socket.on('errorMessage', ({ reason, event, siteId }) => {
			console.warn('Error from socket', { reason, event, siteId });
			if (reason === 'invalidUser') {
				keycloakService.logout();
			}
		});
	}
}

export default new MineceptServerSocket();
