import { SortBy } from '../../../../definition/Enums';
import {
	getAreAllSafetyEventsSelected,
	getAreEventFiltersActivated,
	getMultiSelectedSafetyEvents,
	getSafetyEventsControls,
	getSafetyEventsFilters,
	getSafetyEventsForTable,
    getSafetyEventsVehicles,
    getTypesOfExistingEvents,
} from '../../../../store/selectors/mining/mining.selectors';
import {
	archiveMultiSafetyEvents,
	clearMultiSelect,
	multiSelectAllSafetyEvents,
	multiUnselectAllSafetyEvents,
	resetEventFilters,
	restoreMultiSafetyEvents,
	setEventFilters,
	setNewSafetyEventFlag,
	setSafetyEventSort,
} from '../../../../store/actions/mining.actions';
import { config } from '../../../../services/config';

const sortSafetyEventsOptions = [
	{ label: 'Last Added', value: SortBy.time },
	{ label: 'Event Type', value: SortBy.type },
	{ label: 'Event Severity', value: SortBy.severity },
	{ label: 'Event Vehicle', value: SortBy.vehicle },
];

const selectors = {
	data: getSafetyEventsForTable,
	controls: getSafetyEventsControls,
	filter: getSafetyEventsFilters,
	existingTypes: getTypesOfExistingEvents,
	filtersActivated: getAreEventFiltersActivated,
	areAllSelected: getAreAllSafetyEventsSelected,
	multiSelected: getMultiSelectedSafetyEvents,
	vehicles: getSafetyEventsVehicles
};

const actions = {
	setSort: setSafetyEventSort,
	removeNewMessageAlert: setNewSafetyEventFlag(false),
	clearFilters: resetEventFilters,
	setFilters: setEventFilters,
	selectAll: multiSelectAllSafetyEvents,
	unSelectAll: multiUnselectAllSafetyEvents,
	clearMultiSelect,
	archiveMulti: archiveMultiSafetyEvents,
	restoreMulti: restoreMultiSafetyEvents,
};

const getMenuData = () => {
	const labels = {
		title: 'Events',
		sortTitle: 'Sort Events',
		filterTitle: 'Filter Events',
		typeLabels: config.hazardAndSafetyLabels.safeties,
		sortOptions: sortSafetyEventsOptions,
	};
	return { actions, labels, selectors };
};

export default getMenuData;
