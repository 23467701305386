import 'ol/ol.css';
import proj4 from 'proj4';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { distinctUntilChanged as __distinctUntilChanged, filter as __filter, map as __map } from 'rxjs/operators';
import mapSourceProvider from '../../../core/services/map-source.provider';
import olMapService from '../../../core/services/ol-map.service';
import adasLayersService from '../../../core/services/layers/adas-layers.service';
import { Coords } from '../../../definition/simple-types';
import { selector$ } from '../../../store/store';
import { registerSensor} from '../../sensors/actions/sensors.actions';
import { ISensorState } from '../../sensors/reducers/sensors.reducer';
import { rotationChanged, setMapStaticState, toggleFollowRoute } from '../../../store/actions/map.actions';
import { getMapStaticState$ } from '../../../store/selectors/map.selectors';
import './MapContainer.scss';
import { getAdasLayers } from '../../adas-layers/effects/adas-layers.effects';
import { getAdasLayersMap$, getAdasEditLayer$, getFeatureId$ } from '../../adas-layers/selectors/adas-layers.selectors';
import { IAdasLayersMap, IEditAdasLayer } from '../../adas-layers/reducers/adas-layers.reducer';
import { DrawModes } from '../../../definition/draw-modes';
import { setActiveMenu } from '../../../store/actions/core.actions';
import { getIsGoingReverse$, getMineceptLayers$ } from '../../../store/selectors/mining/mining.selectors';
import { sourceMapOptions } from '../../../definition/source-map-options';
import ObstacleTooltip from '../../mining-truck/obstacleTooltip/ObstacleTooltip';
import { _closeTooltip } from '../../../store/actions/mining.actions';
import mineceptVectorLayerProvider from '../../../core/services/layers/mineceptVectorLayerProvider';
import { MineceptLayers } from '../../../definition/mineceptLayers';
import profileConfig from '../../../core/profiles/profileConfig';
import { convertWgs84ToWebMercator } from '../../../core/services/projections';
import VehicleTooltip from '../../vehicleTooltip/vehicleTooltip';
import ReduxListeners from './ReduxListeners';
import Emitter from '../../../emitter';

const defaultResolution = parseFloat(process.env.REACT_APP_DEFAULT_VIEW_RESOLUTION as string);
const reverseViewResolution = parseFloat(process.env.REACT_APP_REVERSE_VIEW_RESOLUTION as string);
const reverseVehiclePosition = parseFloat(process.env.REACT_APP_REVERSE_AXIS_POSITION as string);
const defaultLatitude = parseFloat(process.env.REACT_APP_DEFAULT_MAP_LAT as string);
const defaultLongitude = parseFloat(process.env.REACT_APP_DEFAULT_MAP_LON as string);
const defaultViewCenter = convertWgs84ToWebMercator([defaultLongitude,defaultLatitude]);

const onMapClick = () => {
	Emitter.emit('mapClicked');
}

class MapContainer extends Component<any, void> {

	private lastViewCenter: [number, number] = defaultViewCenter;
	private lastViewResolution: number = defaultResolution;

	constructor(props) {
		super(props);
	}

	componentDidMount() {
		this.props.getAdasLayers();
		this.setMap();

		this.registerSensors();
	}

	registerSensors() {
		const value = process.env.REACT_APP_SENSORS as string;
		const sensors = JSON.parse(value);
		sensors.forEach((sensor) => {
			this.props.registerSensor(sensor);
		});
	}

	setMap() {

		const layers = this.props.mapSources.flatMap(item => [...mapSourceProvider.provideLayers(item)]);

		const coords = [
			parseFloat(process.env.REACT_APP_DEFAULT_MAP_LAT as string),
			parseFloat(process.env.REACT_APP_DEFAULT_MAP_LON as string)
		];
		const center = proj4('EPSG:3857', coords) as Coords;

		const map = olMapService.createMap({
			target: 'map-container',
			layers,
			center,
			zoom: 17
		});

		const showActiveLayers = () => {
			this.props.mapSources.forEach(layer=>olMapService.showMapLayer(layer));
			Object.keys(this.props.mineceptLayers).forEach(layerId => {
				mineceptVectorLayerProvider.setLayerVisibility(layerId, this.props.mineceptLayers[layerId]);
			});
		};

		const hideMineCeptActiveLayers = () =>{
			Object.keys(this.props.mineceptLayers).forEach(layerId => {
				mineceptVectorLayerProvider.hideLayer(layerId);
			});
		};

		olMapService.followRouteFlag = false;
		olMapService.mapStatic = true;

		// this is to detach follow mode when dragging

		// map.on('pointerdrag', (event: any) => {
		// 	if(!event.pointerEvent.altKey && ! event.pointerEvent.shiftKey){
		// 		this.props.setMapStaticState(true);
		// 	}
		// });

		map.getView().on('change:rotation', () => {
			this.props.rotationChanged(map.getView().getRotation());
		});

		getAdasLayersMap$().subscribe((adasLayersMap: Map<string, IAdasLayersMap>) => {
			if (!adasLayersMap) {
				return;
			}
			Array.from(adasLayersMap.keys()).forEach((layerId: string) => {
				const layer = adasLayersMap.get(layerId);
				let isDisplay = false;
				if (layer) {
					isDisplay = layer.isDisplay;
				}
				olMapService.showAdasLayer(layerId, isDisplay);
			})
		});

		getAdasEditLayer$().subscribe((adasEditLayer : IEditAdasLayer) => {
			if (!adasEditLayer || !adasEditLayer.id) {
				adasLayersService.clearPreviousInteractions();
				return;
			}

			if (!adasEditLayer.drawMode || adasEditLayer.drawMode === DrawModes.NONE) {
				adasLayersService.setLayerStyle(adasEditLayer.id, adasEditLayer.style);
				adasLayersService.selectAndModify(adasEditLayer);
			} else {
				adasLayersService.drawNewFeature(adasEditLayer);
			}

		});

		getFeatureId$().subscribe((featureId : string) => {
			if (!featureId) {
				if (this.props.activeMenu !== 'edit-feature-mode') {
					return;
				}
				this.props.setActiveMenu('edit-layer-mode');
			} else {
				this.props.setActiveMenu('edit-feature-mode');
			}
		});

		Emitter.on('set map.followRoute', value => {
			olMapService.followRouteFlag = value;
		});

		getMapStaticState$().subscribe(value => {
			olMapService.mapStatic = value;
		});

		getMineceptLayers$().subscribe(value => {
			if(value){
				Object.keys(value).forEach(layerId => {
					mineceptVectorLayerProvider.setLayerVisibility(layerId, value[layerId]);
				});
			}
		})

		getIsGoingReverse$().subscribe(value => {
			olMapService.reverseViewFlag = value;
			if(value){
				const view = olMapService.map.getView();
				view.setRotation(profileConfig().flipSafeUnloadingScreen? Math.PI : 0);
				const centerPosition = window.innerHeight*(0.5-reverseVehiclePosition)*reverseViewResolution;
				this.lastViewCenter = olMapService.getCenter();
				view.setCenter([0, -centerPosition]);
				this.lastViewResolution = olMapService.getResolution();
				view.setResolution(reverseViewResolution);
				olMapService.hideLayer(sourceMapOptions.SATELLITE);
				olMapService.hideLayer(sourceMapOptions.OSM);
				olMapService.hideLayer(sourceMapOptions.DETAILED_SATELLITE);
				olMapService.hideLayer(sourceMapOptions.ROADS);
				olMapService.setMapInteractionsActive(false);
				olMapService.clearSelection();
				// mineceptVectorLayerProvider.hideLayer(MineceptLayers.obstacles);
				hideMineCeptActiveLayers();
				mineceptVectorLayerProvider.clearLayerData(MineceptLayers.berm);
				mineceptVectorLayerProvider.showLayer(MineceptLayers.berm);
				this.props._closeTooltip();
			}else{
				showActiveLayers();
				olMapService.setMapInteractionsActive(true);
				mineceptVectorLayerProvider.clearLayerData(MineceptLayers.obstacles);
				mineceptVectorLayerProvider.hideLayer(MineceptLayers.berm);
				mineceptVectorLayerProvider.showLayer(MineceptLayers.obstacles);

				if(profileConfig().onScreenChangeSetViewToDefault){
					olMapService.setResolution(defaultResolution);
					olMapService.setCenter(defaultViewCenter);
				}else{
					olMapService.setResolution(this.lastViewResolution);
					olMapService.setCenter(this.lastViewCenter);
				}

				if(profileConfig().autoSetMapToFollow){
					this.props.setMapStaticState(false);
					this.props.toggleFollowRoute(true);
				}else{
					olMapService.clearRotation();
				}
			}
		});
		selector$("sensors.sensors").pipe(
			__filter(sensors => Boolean(sensors)),
			__map( (sensors: ISensorState) => {
				const sensorsArray = Object.values(sensors);
				return Boolean(sensorsArray.find((sensor: any) => sensor.displayFlags.visible))
			}),
			__distinctUntilChanged()
		).subscribe(value => {
			if(!value){
				this.props.setMapStaticState(true);
			}
		})
	}

	render() {
		return (
			<div className='map-parent'>
				<ReduxListeners/>
				<div id='map-container' onClick={onMapClick}>
					<ObstacleTooltip/>
					{profileConfig().showVehicleTooltip && <VehicleTooltip/>}
				</div>
			</div>
		);
	}
}

const mapStateToProps = (state) => ({
	mapSources: state.map.mapSources,
	mineceptLayers: state.mining.mineceptLayers,
	multiSelected:state.mining.multiSelect,
	activeMenu: state.core.activeMenu
});

const mapDispatchToProps = {
	registerSensor,
	setMapStaticState,
	rotationChanged,
	getAdasLayers,
	setActiveMenu,
	toggleFollowRoute,
	_closeTooltip
};

// @ts-ignore
export default connect(mapStateToProps, mapDispatchToProps)(MapContainer);
