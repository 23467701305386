import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import useStyles from './MiniScreen.css';
import profileConfig from '../../core/profiles/profileConfig';
import { useSnackbar } from 'notistack';
import globals from '../../core/globals';
import logo from '../../assets/images/logo.png';
import MineceptStatusBar from '../../features/mining-truck/MineceptStatusBar/MineceptStatusBar';
import MiniAlert from '../../features/miniAlert/MiniAlert';
import {getObstacleAlerts, getWarningSectors} from '../../store/selectors/mining/mining.selectors';
import AdasAlertsSound from '../../features/adas-alerts/sounds/AlertsSound';
import VolumeButton from '../../features/muteButton/VolumeButton';
import clientLogo from '../../assets/images/clientLogo.png';
import { showMiniMap } from "../../services/obstacles";
import { isShowConnected } from '../../services/connected';
import {isShowAlertsInWheel} from '../../services/obstacles';

const MiniScreen = () => {
	const miniMapSize = profileConfig().vertical ?
		Math.floor(screen.width * 0.95) :
		Math.floor(screen.height * 0.70);
	const classes = useStyles({miniMapSize});
	const { enqueueSnackbar } = useSnackbar();
	const obstacles = useSelector(getObstacleAlerts);
	const sectors = useSelector(getWarningSectors);



	useEffect(()=>{
		globals.actions.setSnack = enqueueSnackbar;
	});

	useEffect(()=>{
		if(profileConfig().vertical){
			return 	window.resizeTo(600,1024);
		}
		window.resizeTo(1024,600);
	},[]);

	return (
		<div className={classes.root}>
			{showMiniMap(sectors, obstacles,miniMapSize, classes.miniMap)};
			{ !isShowAlertsInWheel && <div className={classes.alerts}>
				{
					obstacles.map((obstacle, key) => <MiniAlert severity={obstacle.severity} type={obstacle.obstacleType} description={obstacle.obstacleDescription} key={key}/>)
				}
			</div>}
			<div className={classes.logoContainer}>
				<img src={logo} className={classes.logo}/>
				<div className={classes.version}>
					{process.env.REACT_APP_APPLICATION_VERSION}
				</div>
			</div>
			{profileConfig().showClientLogo && <img src={clientLogo} className={classes.clientLogo}/>}
			{isShowConnected() && profileConfig().showIsConnectedBar && <div className={classes.statusBar}><MineceptStatusBar /></div>}
			<AdasAlertsSound obstacles={obstacles}/>
			<div className={classes.muteButton}><VolumeButton/></div>
		</div>
	);
};

export default MiniScreen;
