import React from 'react';
import profileConfig from '../core/profiles/profileConfig';
import AlertInWheel from '../features/alertInWheel/alertInWheel';
import MinMap from '../features/mining-truck/miniMap/MinMap';
import AlertsSound from "../features/adas-alerts/sounds/AlertsSound";

const appIsShowAlertsInWheel = process.env.REACT_APP_IS_SHOW_ALERTS_IN_WHEEL;
const isShowAlertsInWheelContent = appIsShowAlertsInWheel && appIsShowAlertsInWheel.trim().toLowerCase();
export const isShowAlertsInWheel = isShowAlertsInWheelContent && isShowAlertsInWheelContent === 'true';


const obstacleMinSeverityToAlert = parseInt(process.env
	.REACT_APP_OBSTACLE_MINIMUM_SEVERITY_TO_ALERT as string);

export const getHighestSeverityObstacle = obstacles => {
	if (!obstacles || obstacles.length === 0) {
		return undefined;
	}
	const maxSeverityObstacle = obstacles.reduce((prev, curr) =>
		curr.severity > prev.severity ? curr : prev
	);
	if (maxSeverityObstacle.severity < obstacleMinSeverityToAlert) {
		return undefined;
	}
	return maxSeverityObstacle;
};

const showAlert = (obstacles, miniMapSize: number,small:boolean=false) => {
	const maxSeverityObstacle = getHighestSeverityObstacle(obstacles);
	if (!maxSeverityObstacle || (maxSeverityObstacle.severity===undefined && maxSeverityObstacle.obstacleType===undefined)) {
		return <></>;
	}
	return (
		<AlertInWheel
			size={miniMapSize}
			severity={maxSeverityObstacle.severity}
			type={maxSeverityObstacle.obstacleType}
			description={maxSeverityObstacle.obstacleDescription}
			small={small}
			vertical={Boolean(profileConfig().vertical)}
		/>
	);
};

export const showMiniMap = (
	sectors,
	obstacles,
	miniMapSize: number,
	miniMapClass: string,
	small?:boolean,
) => (
	<div className={miniMapClass}>
		<AlertsSound obstacles={obstacles}/>
		<MinMap size={miniMapSize} sectors={sectors} obstacles={obstacles} />
		{isShowAlertsInWheel && showAlert(obstacles, miniMapSize,small)}
	</div>
);
