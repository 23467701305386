import React from 'react';
import { Bar } from 'react-chartjs-2';
import { config } from '../../../../services/config';
import { getSeverityColor } from '../../../../services/icons-generator-service/icons-generator-service';
import { IMineceptDashboardOverallSeverityBySite } from '../../../../store/reducers/mining/IMiningReducerState';

interface IProps {
    graph: IMineceptDashboardOverallSeverityBySite;
    getSiteLabels: (sites: string[]) => string[];
}

const Chart = ({ graph, getSiteLabels }: IProps) => (
    <Bar
        options={{
            plugins: {
                title: {
                    display: true,
                    text: 'Safety events by severity per site',
                },
                datalabels: {
                    display: config.general.dashboard.showDataLabels.overallSeverityBySite,
                    color: 'black'
                }
            },
            indexAxis: 'y',
            maintainAspectRatio: false,
            scales: {
                y: { beginAtZero: true, stacked: true },
            },
        }}
        data={{
            labels: getSiteLabels(graph.sites || []),
            datasets: Object.entries(graph.data)
                .map(([key, dataset]) => ({ key: parseInt(key), dataset }))
                .map(({ key, dataset }) => ({
                    label: `severity ${key}`,
                    data: dataset,
                    backgroundColors: getSeverityColor(key),
                    borderColor: getSeverityColor(key),
                    borderWidth: 1,
                    stack: 'stack'
                }))
        }}
    />
)

export default Chart;