import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import './styles/globalVariables.css';
import App from './App';
import {Provider} from 'react-redux';
import * as serviceWorker from './serviceWorker';
import configureStore, {createSelector} from './store/store';
import AdasAlertsSound from './features/adas-alerts/sounds/AlertsSound';
import {initConfig, config} from "./services/config";
import {generateCache as cacheObstacleStyles} from './core/services/layers/styles/obstacleLayer-styles';
import {generateCache as cacheSafetyEventStyles} from './core/services/layers/styles/safetyEventLayer-styles';
import {generateCache as cacheToasterHazardIcons} from './core/services/icons/toasterHazardIcons';
import Keycloak from "keycloak-js";
import {ReactKeycloakProvider} from "@react-keycloak/web";
import VideoProvider from "./store/context/VideoProvider";

export const store = configureStore();
export const selector = createSelector(store);

const initializeCache = async () => {
    const startTime = Date.now();

    cacheObstacleStyles();
    cacheSafetyEventStyles();
    cacheToasterHazardIcons();

    const totalTimeSec = (Date.now() - startTime) / 1000;
    console.debug(`Initializing cache took: ${(Math.round(totalTimeSec * 100) / 100)} seconds`);
}

const OptionalKeyClockProvider: React.FC = ({children}) => {
    if (!config.isRequiredLogin) {
        return (
            <React.Fragment>
                {children}
            </React.Fragment>
        )
    }

    const keycloak = new Keycloak(config.general.keycloak);
    return (
        <ReactKeycloakProvider authClient={keycloak}>
            {children}
        </ReactKeycloakProvider>
    )
}

const init = async () => {
    await initConfig();
    await initializeCache();
    ReactDOM.render(
        <Provider store={store}>
            <OptionalKeyClockProvider>
                <VideoProvider>
                    <AdasAlertsSound obstacles={store.getState().mining.obstacles}/>
                    <App/>
                </VideoProvider>
            </OptionalKeyClockProvider>
        </Provider>
        , document.getElementById('root'));
};


init();
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
